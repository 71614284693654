/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import renderMultiSelectField from './MultiSelect';
import { rules } from '../validate';

class SingleMultiSelectSection extends PureComponent {
  componentDidMount() {
    const { changeFieldValue, name, defaultValue } = this.props;
    if (defaultValue) {
      changeFieldValue(name, defaultValue);
    }
  }

  render() {
    const {
      label, mode, name, options, onChange, validate, onChangeGetName, loading, defaultValue,
      initialValues, disabledOptionDescription, maxMenuHeight, placeholder, isDisabled,
    } = this.props;
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">{label}</span>
        <div className="form__form-group-field">
          {mode !== 'view'
            ? (
              <>
                <Field
                  name={name}
                  validate={validate && validate.map((rule) => rules[rule])}
                  component={renderMultiSelectField}
                  onChangeGetName={onChangeGetName}
                  defaultValue={defaultValue}
                  loading={loading}
                  isEmpty
                  type="text"
                  isHidden="true"
                  placeholder={placeholder}
                  options={options}
                  onChange={onChange}
                  maxMenuHeight={maxMenuHeight}
                  disabledOptionDescription={disabledOptionDescription}
                  isDisabled={isDisabled}
                />
                <span className="form__form-group-character" style={{ fontSize: '11px' }}>
                  Birden fazla seçim yapabilirsiniz.
                </span>
              </>
            )

            : initialValues !== undefined && initialValues !== null
              ? <p style={{ fontStyle: 'italic' }}>{this.getLayeredObjectValue(initialValues, name, options)}</p>
              : <p>-</p>}
        </div>
      </div>
    );
  }
}

export default SingleMultiSelectSection;
