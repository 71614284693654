/* eslint-disable no-param-reassign */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Button,
  Card, CardBody, Col,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import Alert from '../../../../shared/components/Alert';
// import { info, viewInfo } from '../../../../redux/actions/form/infoAction';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import NewApplicationFormTabs from '../Containers/NewApplicationFormTabs';
import NewApplicationForm from './NewApplicationForm';
import { postAppeal, putAppeal, getAppeal } from '../../../redux/actions/appealAction';
import { upload, uploadWithFileName } from '../../../redux/actions/uploadAction';
import { download, downloadWithId } from '../../../redux/actions/downloadAction';
import { list } from '../../../redux/actions/listAction';
import { postByIdWithId, putByIdWithId } from '../../../redux/actions/byIdAction';

class AddApplicationForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      value: 0,
    };
  }

  componentDidMount() {
    const { getAppeal, history, listAttachmentDocuments } = this.props;
    if (history && history.location && history.location.state && history.location.state.appealId) {
      getAppeal(history?.location?.state?.appealId);
      listAttachmentDocuments(history?.location?.state?.appealId);
      this.setState({mode: 'edit'});
    } else {
      this.setState({mode: 'create'});
    }
  }

  componentDidUpdate(prevProps) {
    const { updateAppealSuccess, idAppeal, sendReviewAppeal, getAppeal, uploadAppealAttachmentDocumentSuccess, setDatesAppealSuccess,
      sendReviewAppealSuccess, uploadDocumentAppealSuccess, saveAppealSuccess, appealDocumentAttachmentDeleteByIdSuccess, deleteAppealSuccess,
      saveAppeal, cancelAppealSuccess, history, listAttachmentDocuments, updateAppealId } = this.props;
    const { value } = this.state;
    if ((prevProps.sendReviewAppealSuccess !== sendReviewAppealSuccess && sendReviewAppealSuccess)) {
      getAppeal(sendReviewAppeal);
    }
    if ((prevProps.setDatesAppealSuccess !== setDatesAppealSuccess && setDatesAppealSuccess)) {
      getAppeal(idAppeal.id);
    }
    if ((prevProps.saveAppealSuccess !== saveAppealSuccess && saveAppealSuccess)) {
      getAppeal(saveAppeal);
      this.setState({mode: 'edit'});
    }
    if ((prevProps.updateAppealSuccess !== updateAppealSuccess && updateAppealSuccess)) {
      getAppeal(updateAppealId);
      this.setState({mode: 'edit'});
    }
    if ((prevProps.cancelAppealSuccess !== cancelAppealSuccess && cancelAppealSuccess) ||
      (prevProps.deleteAppealSuccess !== deleteAppealSuccess && deleteAppealSuccess)) {
      history.push('/hospital_appeals');
    }

    if (prevProps.updateAppealSuccess !== updateAppealSuccess && updateAppealSuccess) {
      // this.setValue(value + 1);
      setTimeout(() => this.setValue(value + 1), 500);
    }
    if (prevProps.saveAppealSuccess !== saveAppealSuccess && saveAppealSuccess) {
      // this.setValue(value + 1);
    }
    if (prevProps.uploadDocumentAppealSuccess !== uploadDocumentAppealSuccess && uploadDocumentAppealSuccess) {
      getAppeal(idAppeal.id);
      listAttachmentDocuments(idAppeal.id);
    }
    if (prevProps.uploadDocumentAppealSuccess !== uploadDocumentAppealSuccess && uploadDocumentAppealSuccess) {
      getAppeal(idAppeal.id);
      listAttachmentDocuments(idAppeal.id);
    }
    if ((prevProps.uploadAppealAttachmentDocumentSuccess !== uploadAppealAttachmentDocumentSuccess && uploadAppealAttachmentDocumentSuccess)
    || (prevProps.appealDocumentAttachmentDeleteByIdSuccess !== appealDocumentAttachmentDeleteByIdSuccess && appealDocumentAttachmentDeleteByIdSuccess)) {
      listAttachmentDocuments(idAppeal.id);
    }
  }
  setValue = (newValue) => {
    this.setState({value: newValue});
  }
  onSubmitValues = (values) => {
    const { onSubmit, updateAppeal, appealId, idAppeal } = this.props;
    const { mode } = this.state;
    delete values.endDate;
    delete values.startDate;
    if (mode === 'edit') {
      updateAppeal(values);
    } else {
      onSubmit(values);
    }
  }

  cancelAppeal = (id) => {
    const { cancel } = this.props;
    cancel(id);
  }
  deleteAppeal = (id) => {
    const { deleteAppeal } = this.props;
    deleteAppeal(id);
  }

  onChangePdf = (e, id) => {
    const { uploadDocument, initialValues } = this.props;
    const { selectedFile } = this.state;
    if (e.target.files[0]) {
      uploadDocument(e.target.files[0], id);
    }
  }

  downloadDocument = (id) => {
    const { downloadDoc } = this.props;
    downloadDoc(id);
  }

  downloadAttachmentDocument = (appealId, docId) => {
    const { downloadAttachmentDoc } = this.props;
    downloadAttachmentDoc(appealId, docId);
  }
  deleteAttachmentDocument = (appealId, docId) => {
    const { deleteAttachmentDocument } = this.props;
    deleteAttachmentDocument(appealId, docId);
  }

  listAttachmentDocuments = (appealId) => {
    const { listAttachmentDocuments } = this.props;
    listAttachmentDocuments(appealId);
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  onSubmitAvailableDates = (id, values) => {
    const { appealSetDates } = this.props;
    appealSetDates(id, values);
  }

  toggleExtraDocModal = () => {
    this.setState((prevState) => ({ isExtraDocModalOpen: !prevState.isExtraDocModalOpen }));
  }

  onSubmitExtraDocument = (values) => {
    const { uploadDocumentWithTitle, idAppeal } = this.props;
    uploadDocumentWithTitle(values, idAppeal.id);
  }
  appealSendReview = (id) => {
    const { appealSendReview } = this.props;
    appealSendReview(id);
  }

  render() {
    const {history, formLoading, createAppealLoading, idAppealLoading, appealDocumentAttachmentList, idAppeal, createAppeal, saveAppealSuccess, updateAppealSuccess} = this.props;
    const {mode, value, isModalOpen, isExtraDocModalOpen} = this.state;
    return (
      <Col md={12} lg={12}>
        <Button className="mb-2 px-4" color="outline-info" size="sm" onClick={() => history.push('/hospital_appeals')}> <i className="lnr lnr-arrow-left me-2"></i> Geri</Button>
        <Card>
          <CardBody className="p-0 p-md-2">
            <h5 className="ms-3">Yeni Başvuru Oluştur</h5>
            <NewApplicationFormTabs
              initialValues={mode === 'edit' ? idAppeal && idAppeal : createAppeal && createAppeal}
              formLoading={idAppealLoading || createAppealLoading}
              component={NewApplicationForm}
              onSubmitValues={this.onSubmitValues}
              sendReviev={this.sendReviev}
              updateAppealSuccess={updateAppealSuccess}
              saveAppealSuccess={saveAppealSuccess}
              cancelAppeal={this.cancelAppeal}
              value={value}
              onChangePdf={this.onChangePdf}
              setValue={this.setValue}
              downloadDocument={this.downloadDocument}
              downloadAttachmentDocument={this.downloadAttachmentDocument}
              deleteAttachmentDocument={this.deleteAttachmentDocument}
              listAttachmentDocuments={this.listAttachmentDocuments}
              appealDocumentAttachmentList={appealDocumentAttachmentList}
              isModalOpen={isModalOpen}
              toggle={this.toggle}
              appealSendReview={this.appealSendReview}
              onSubmitAvailableDates={this.onSubmitAvailableDates}
              isExtraDocModalOpen={isExtraDocModalOpen}
              toggleExtraDocModal={this.toggleExtraDocModal}
              onSubmitExtraDocument={this.onSubmitExtraDocument}
              deleteAppeal={this.deleteAppeal}
              history={history}
            >
            </NewApplicationFormTabs>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit(values) {
      dispatch(postAppeal('save', values));
    },
    updateAppeal(values) {
      dispatch(putAppeal('update', values));
    },
    appealSendReview(id) {
      dispatch(putAppeal('send/review', {}, id));
    },
    appealSetDates(id, values) {
      dispatch(postAppeal('set/dates', values, id));
    },
    getAppeal(id) {
      dispatch(getAppeal('id', id));
    },
    cancel(id) {
      dispatch(putAppeal('cancel', {}, id));
    },
    deleteAppeal(id) {
      dispatch(putAppeal('delete', {}, id));
    },
    uploadDocument(file, id) {
      dispatch(upload('appeal/document/appointment', file, id));
    },
    downloadDoc(id) {
      dispatch(downloadWithId('appeal/document/appointment', id));
    },
    uploadDocumentWithTitle(values, id) {
      dispatch(uploadWithFileName('appeal/document/attachment', values, id));
    },
    downloadAttachmentDoc(appealId, docId) {
      dispatch(downloadWithId('appeal/document/attachment', appealId, docId));
    },
    deleteAttachmentDocument(appealId, docId) {
      dispatch(putByIdWithId('appeal/document/attachment/delete', appealId, docId));
    },
    listAttachmentDocuments(appealId) {
      dispatch(list('appeal/document/attachment', false, appealId));
    },

  };
};
const mapStateToProps = (state, ownProps) => ({
  idAppeal: state.idAppeal.data,
  idAppealLoading: state.idAppeal.loading,
  createAppeal: state.createAppeal.data,
  createAppealLoading: state.createAppeal.loading,
  idAppealSuccess: state.idAppeal.success,
  updateAppealSuccess: state.updateAppeal.success,
  saveAppealSuccess: state.saveAppeal.success,
  saveAppeal: state.saveAppeal.data,
  sendReviewAppealSuccess: state.sendReviewAppeal.success,
  sendReviewAppeal: state.sendReviewAppeal.data,
  setDatesAppealSuccess: state.setDatesAppeal.success,
  setDatesAppeal: state.setDatesAppeal.data,
  cancelAppealSuccess: state.cancelAppeal.success,

  updateAppealId: state.updateAppeal.data,
  uploadDocumentAppealSuccess: state.uploadDocumentAppeal.success,
  uploadAppealAttachmentDocumentSuccess: state.uploadAppealAttachmentDocument.success,
  appealDocumentAttachmentDeleteByIdSuccess: state.appealDocumentAttachmentDeleteById.success,

  appealDocumentAttachmentList: state.appealDocumentAttachment.data,

  deleteAppealSuccess: state.deleteAppeal.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApplicationForm);
