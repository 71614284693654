/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
/* eslint-disable no-extend-native */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable  arrow-body-style */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */

import React, { PureComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import tr from 'date-fns/locale/tr';
import { isSameDay, startOfToday, endOfDay } from 'date-fns';

registerLocale('tr', tr);

class DateTimePickerField extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    isClearable: PropTypes.bool,
    excludePastTimesBool: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    value: '',
    placeholder: '',
    maxDate: null,
    minDate: null,
    excludePastTimesBool: false,
    isClearable: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      count: 0,
      minTime: this.calculateMinTime(new Date()),
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value === '' && value !== prevProps.value) {
      let minValue = value;
      if (this.isString(value)) {
        minValue = this.formatDate(value);
      }
      this.setState({
        minTime: this.calculateMinTime(minValue),
      });
    }
  }

  calculateMinTime = (date) => isSameDay(date, new Date()) ? new Date() : startOfToday();

  formatDate = (dateString) => {
    if (dateString !== undefined) {
      const splitted = dateString.split('/');
      const formattedTime = new Date(splitted[2], splitted[1] - 1, splitted[0]);
      return formattedTime;
    }
    return null;
  }

  isString = (value) => {
    return typeof value === 'string' || value instanceof String;
  }

  handleChange = (date) => {
    const { onChange, disableTime } = this.props;
    // const utcOffsetMinutes = new Date().getTimezoneOffset();
    // addMinutes(date, -utcOffsetMinutes);
    // const newDate = moment(date).format('DD/MM/YYYY HH:MM');
    this.setState({
      count: 1,
      startDate: date,
      minTime: this.calculateMinTime(date),
    });
    if (date != null) {
      if (disableTime) {
        onChange(date.toLocaleString('en-GB').split(',')[0]);
      } else {
        onChange(date.toLocaleString('en-GB').replace(',', ''));
      }
    }
  };

changeDateFormat = (date) => {
  if (date) {
    return new Date(date.split('/')[2], date.split('/')[1] - 1, date.split('/')[0]);
  } return date;
}

render() {
  const { startDate, count, minTime } = this.state;
  const {
    name, value, maxDate, minDate, disableTime, isClearable, placeholder, excludePastTimesBool,
  } = this.props;
  const MobileTimeInput = () => {
    return (
      <input
        value={value}
        onChange={(e) => this.handleChange(e.target.value)}
      />
    );
  };
  return (
    <div className="date-picker">
      <DatePicker
        timeFormat="HH:mm"
        className={name}
        value={value}
        selected={(value !== '' && value !== undefined && this.isString(value) && count === 0) ? this.formatDate(value) : startDate}
        onChange={this.handleChange}
        locale="tr"
        customTimeInput={<MobileTimeInput />}
        dateFormat="dd/MM/yyyy"
        dropDownMode="select"
        maxDate={maxDate && this.formatDate(maxDate)}
        minDate={minDate && this.formatDate(minDate)}
        isClearable={isClearable}
        placeholderText={placeholder}
        showTimeSelect={!disableTime}
        timeIntervals={10}
        withPortal={window.innerWidth <= 768}
        minTime={excludePastTimesBool ? minTime : startOfToday()}
        maxTime={endOfDay(new Date())}
      />
    </div>
  );
}
}

const renderDateTimePickerField = (props) => {
  const {
    input, meta, maxDate, minDate, isClearable, placeholder, disableTime, excludePastTimesBool, dateFormat,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <DateTimePickerField
        {...input}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat={dateFormat}
        disableTime={disableTime}
        isClearable={isClearable}
        placeholder={placeholder}
        excludePastTimesBool={excludePastTimesBool}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  excludePastTimesBool: PropTypes.bool,
};
renderDateTimePickerField.defaultProps = {
  meta: null,
  maxDate: null,
  minDate: null,
  isClearable: true,
  placeholder: '',
  excludePastTimesBool: false,
};

export default ((renderDateTimePickerField));
