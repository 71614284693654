/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import DateTimeFormSection from '../../../shared/components/generic-form/FormSections/SingleSections/DateTimeFormSection';
import MultiSelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSections/MultiSelectSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import { list } from '../../../redux/actions/listAction';
import { addNotification } from '../../../redux/actions/notificationActions';

class NewMeetingDateandTeam extends PureComponent {
  constructor() {
    super();
    this.state = {
      maxExternalMember: 3,
      maxCouncilMember: 3,
      submitted: false,
      selectedData: null
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
    const { getExternalVisitors, getCouncilMembers } = this.props;
    getExternalVisitors();
    getCouncilMembers();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, tcNo, id, selectedOthers, selectedUsers } = this.props;
  }
  
  handleFormSubmit(values) {
    console.log('values', values);
    if (!values) {
      console.error('Form değerleri boş');
      return;
    }

    // Seçilen tarihin geçerli aralıklarda olup olmadığını kontrol et
    const normalizeDate = (date) => {
        if (!date || typeof date !== "string") {
          console.warn("Uyarı: normalizeDate fonksiyonuna gelen tarih geçersiz:", date);
          return null;
        }
      
        if (date.includes("/")) {
          // Tarih formatı DD/MM/YYYY ise, YYYY-MM-DD'ye çevir
          const [day, month, year] = date.split("/");
          date = `${year}-${month}-${day}`;
        }
      
        const d = new Date(date);
        
        if (isNaN(d.getTime())) {
          console.error("Geçersiz tarih formatı:", date);
          return null;
        }
        
        d.setHours(0, 0, 0, 0); // Saatleri sıfırla, sadece günü karşılaştır
        return d;
      };
      
      
      
      // Seçilen tarihi normalleştir
      const selectedDate = normalizeDate(values.visitDate);
      console.log("Seçilen Tarih (Düzeltilmiş):", selectedDate);
      
      // Tarih aralıklarını kontrol et
      const isDateValid = this.props.availableDates.some(range => {
        const start = normalizeDate(range.startDate);
        const end = normalizeDate(range.endDate);
      
        console.log("Karşılaştırılan Aralık:", start, " - ", end);
      
        return selectedDate && start && end && (selectedDate >= start && selectedDate <= end);
      });
      
      console.log('isDateValid:', isDateValid);
      
      if (!isDateValid) {
        this.props.dispatch(
          addNotification('Lütfen geçerli tarih aralıklarından bir tarih seçiniz', 'error')
        );
        return;
      }
      
      
    const visitMemberDtoList = [];
    
    // filteredOptions'tan seçilen üyelerin bilgilerini al
    const {
      pristine, submitting, toggle, reset, handleSubmit, initialValues,
      councilMemberList, formLoading, title, externalVisitorList, selectedUsers, selectedOthers,
    } = this.props;

    const allOptions = [...(externalVisitorList || []), ...(councilMemberList || [])].map((user) => {
      return {
        id: user?.id, 
        value: user?.id, 
        label: `${user?.title || ''} ${user?.name || ''} ${user?.surname || ''}`.trim()
      };
    });

    // Seçilen üyelerin tam bilgilerini bul
    const selectedMembers = {
      councilMembers: values.councilMembers?.map(member => 
        allOptions.find(opt => opt.id === member.id)
      ).filter(Boolean) || [],
      externalMembers: values.externalMembers?.map(member => 
        allOptions.find(opt => opt.id === member.id)
      ).filter(Boolean) || []
    };

    // Ekip başkanını bul
    const headOfCommittee = allOptions.find(opt => opt.id === values.headOfCommitteeId);

    // visitMemberDtoList'i oluştur
    selectedMembers.councilMembers.forEach(member => {
      visitMemberDtoList.push({
        councilMemberDto: {
          id: member.id
        },
        ...(member.id === values.headOfCommitteeId ? { headOfCommittee: true } : {})
      });
    });

    selectedMembers.externalMembers.forEach(member => {
      visitMemberDtoList.push({
        externalVisitorDto: {
          id: member.id
        },
        ...(member.id === values.headOfCommitteeId ? { headOfCommittee: true } : {})
      });
    });

    // Seçilen bilgileri state'e kaydet
    this.setState({
      submitted: true,
      selectedData: {
        visitDate: values.visitDate,
        headOfCommittee,
        councilMembers: selectedMembers.councilMembers,
        externalMembers: selectedMembers.externalMembers
      }
    });

    // Parent komponente gönder
    this.props.onSubmit({
      visitDate: values.visitDate,
      visitMemberDtoList
    });
  }

  renderSelectedInfo() {
    const { selectedData } = this.state;
    console.log('selectedData', selectedData);
    return (
      <div className="p-3 border rounded">
        <h6 className="mb-4">Seçilen Ekip ve Tarih Bilgileri</h6>
        
        <div className="mb-3">
          <strong>Seçilen Tarih: </strong>
          
          {/* {new Date(selectedData.visitDate).toLocaleDateString('tr-TR')} */}
          {selectedData.visitDate}
        </div>

        {/* {selectedData.headOfCommittee && (
          <div className="mb-3">
            <strong>👑 Ekip Başkanı: </strong>
            {selectedData.headOfCommittee.name} {selectedData.headOfCommittee.surname}
          </div>
        )} */}

        {selectedData.councilMembers.length > 0 && (
          <div className="mb-3">
            <strong>👥 Kurul Üyeleri:</strong>
            <ul className="list-unstyled ms-3 mt-1">
              {selectedData.councilMembers.map((member, idx) => (
                <li key={idx}>
                  • {member.label}
                  {member.id === selectedData.headOfCommittee?.id && ' (Başkan)'}
                </li>
              ))}
            </ul>
          </div>
        )}

        {selectedData.externalMembers.length > 0 && (
          <div className="mb-3">
            <strong>👥 Dış Değerlendiriciler:</strong>
            <ul className="list-unstyled ms-3 mt-1">
              {selectedData.externalMembers.map((member, idx) => (
                <li key={idx}>
                  • {member.label}
                  {member.id === selectedData.headOfCommittee?.id && ' (Başkan)'}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { submitted } = this.state;
    
    if (submitted) {
      return this.renderSelectedInfo();
    }

    const {
      pristine, submitting, toggle, reset, handleSubmit, initialValues,
      councilMemberList, formLoading, title, externalVisitorList, selectedUsers, selectedOthers,
      startDate, endDate, availableDates
    } = this.props;
    
    console.log('councilMemberList', councilMemberList);
    console.log('externalVisitorList', externalVisitorList);
    const allOptions = [...(externalVisitorList || []), ...(councilMemberList || [])].map((user) => {
      return {
        id: user?.id, 
        value: user?.id, 
        label: `${user?.title || ''} ${user?.name || ''} ${user?.surname || ''}`.trim()
      };
    });

    const selectedIds = [
      ...(selectedOthers || []), 
      ...(selectedUsers || [])
    ].map(item => item?.id).filter(Boolean);
console.log('selectedIds', selectedIds);
console.log('allOptions', allOptions);
    const safeSelectedIds = Array.isArray(selectedIds) ? selectedIds : [];
    const filteredOptions = allOptions.filter(item => 
      item?.id && safeSelectedIds.includes(item.id)
    );
      

    console.log('filteredOptions', filteredOptions);
    return (
      <Col md={12} lg={12}>
        <h5>{title}</h5>
        <FormWithLoading loading={formLoading} className="form form--horizontal px-2">
          <Row>
            <h6>Hastanenin Müsait Olduğu Tarih Aralıkları:</h6>
            {availableDates && availableDates.map((dateRange, index) => (
              <div key={index} className="mb-2">
                <strong>{index + 1}. Tarih Aralığı: </strong>
                {dateRange.startDate} - {dateRange.endDate}
              </div>
            ))}
            
            <Col xs={12} className="mb-2">
              <DateTimeFormSection
                name="visitDate"
                label="Yukarıdaki tarih aralıklarından birini seçiniz"
                placeholder="Uygun Tarih Seçiniz"
                disableTime={true}
                validate={['required']}
                // Seçilebilecek tarihleri kısıtla
                minDate={availableDates?.[0]?.startDate}
                maxDate={availableDates?.[availableDates.length - 1]?.endDate}
              />
            </Col>
            {initialValues.status !== 'REVISIT_WAITING_DATE_SELECTION' && (
            <>
              <Col xs={6}>
                <MultiSelectSection
                  label="Akreditasyon Kurulu Üyeleri"
                  name="councilMembers"
                  items={councilMemberList && councilMemberList.map((councilMember) => {
                    return {id: councilMember.id, value: councilMember.id, label: councilMember.name + ' ' + councilMember.surname + ' (' + councilMember.title + ')' };
                  })}
                  max={3}
                />
              </Col>
              <Col xs={6}>
                <MultiSelectSection
                  label=" Dış Değerlendirici"
                  name="externalMembers"
                  items={externalVisitorList && externalVisitorList.map((externalVisitor) => {
                    return {
                      id: externalVisitor.id, value: externalVisitor.id, label: externalVisitor.title + ' ' + externalVisitor.name + ' ' + externalVisitor.surname,
                    };
                  })}
                  max={3}
                />
              </Col>
              <Col xs={12}>
                <SelectSection
                  label="Seçilen Üyeler Arasından Ekip Başkanı Seçiniz"
                  name="headOfCommitteeId"
                  placeholder="Seçiniz"
                  options={filteredOptions}
                  validate={['required']}
                />
              </Col>
            </>
            )}
          </Row>

          <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
            <>
              <Row className="w-100 mt-2">
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button 
                    style={{ width: '180px' }} 
                    type="button" 
                    onClick={handleSubmit(this.handleFormSubmit)}
                    disabled={pristine || submitting} 
                    color="success" 
                    size="sm"
                  >
                    Kaydet
                  </Button>
                </Col>
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} onClick={() => toggle()} color="secondary" size="sm">
                    İptal
                  </Button>
                </Col>
              </Row>
            </>
          </ButtonToolbar>
        </FormWithLoading>
      </Col>
    );
  }
}
const selector = formValueSelector('Select_Available_Dates_Form');

const mapDispatchToProps = (dispatch) => {
  return {
    getExternalVisitors() {
      dispatch(list('external/visitor'));
    },
    getCouncilMembers() {
      dispatch(list('council/member'));
    },

  };
};
const mapStateToProps = (state, ownProps) => ({
  selectedUsers: selector(state, 'councilMembers') || [],
  selectedOthers: selector(state, 'externalMembers') || [],
  externalVisitorList: state.externalVisitorList.data,
  councilMemberList: state.councilMemberList.data,

});

NewMeetingDateandTeam = reduxForm({
  form: 'Select_Available_Dates_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewMeetingDateandTeam);

export default connect(mapStateToProps, mapDispatchToProps)(NewMeetingDateandTeam);
