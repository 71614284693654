/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const steps = [
  { id: "hospital_register", text: "Hastane Kayıt" },
  { id: "hospital_reject", text: " Red" },
  { id: "hospital_cancel", text: "İptal" },
  { id: "application", text: "Akreditasyon Başvuru" },
  { id: "final_reject", text: "Red" },
  { id: "revise", text: "Revize" },
  { id: "application_cancel", text: "İptal" },
  { id: "final_approved", text: "Onaylandı" },
  { id: "on_site_eval", text: "Yerinde Değerlendirme" },
  { id: "accreditation", text: "Akreditasyon" },
  { id: "re_evaluation", text: "Yeniden Değerlendirme" },
  { id: "waiting_date", text: "Yeniden değerlendirme için tarih bekleniyor." },
  { id: "accreditation_reject", text: "Akreditasyon Red" },
  { id: "board_approval", text: "Kurul Onayı" },
  { id: "board_reject", text: "Kurul Reddi" },
];
//READY_TO_VISIT
//iptal gelecek
const links = [
  { from: "hospital_register", to: "hospital_reject" },
  { from: "hospital_register", to: "hospital_cancel" },
  { from: "hospital_register", to: "application" },
  { from: "application", to: "final_reject" },
  { from: "application", to: "revise" },
  { from: "application", to: "final_approved" },
  { from: "application", to: "application_cancel" },
  { from: "final_approved", to: "on_site_eval" },
  { from: "on_site_eval", to: "accreditation" },
  { from: "on_site_eval", to: "re_evaluation" },
  { from: "re_evaluation", to: "waiting_date" },
  { from: "on_site_eval", to: "accreditation_reject" },
  { from: "accreditation", to: "board_approval" },
  { from: "accreditation", to: "board_reject" },
];


const stepMessages = {
  hospital_register: (
    <>
      Kurumlar KEPAN Akreditasyon sistemine üye olarak Akreditasyon sürecini başlatırlar. Üyelikleri KEPAN yetkililerince onaylanan kurumlar, akredistasyon süreçlerini başlatabilirler. Kurumların sisteme bir kez üye olmaları yeterlidir. NDB akreditasyona hazır oldukları zaman Kepan akreditasyonuna başvurabilir. 
      <p><label style={{ fontWeight: 'bold' }}> Tarih seç sekmesinden tarih aralıklarını seçebilirsiniz.</label> </p>
    </>
  ),
  hospital_reject: "Hastane başvurusu reddedildi. Tekrar başvuru yapılabilir.",
  application: (
    <>
      Kurumlar KEPAN Akreditasyon sistemine üye olarak Akreditasyon sürecini başlatırlar. Üyelikleri KEPAN yetkililerince onaylanan kurumlar, akredistasyon süreçlerini başlatabilirler. Kurumların sisteme bir kez üye olmaları yeterlidir. NDB akreditasyona hazır oldukları zaman Kepan akreditasyonuna başvurabilir. 
      <p><label style={{ fontWeight: 'bold' }}> Tarih seç sekmesinden tarih aralıklarını seçebilirsiniz.</label> </p>
    </>
  ),
  hospital_cancel: "Hastane başvurusu iptal edildi. Tekrar başvuru yapılabilir.",
  final_reject: "Akreditasyon başvurusu reddedildi. Tekrar başvuru yapılabilir.",
  revise: "Başvuru için revizyon istendi.  Gerekli düzenlemeler yapılmalıdır.",
  final_approved: "Başvuru onaylandı. Süreç devam ediyor.",
  visit_plan: "Ziyaret planı oluşturulacak. ",
  on_site_eval:
    "İlgili Nütrisyon Destek Birimi (NDB)’nin başvuru sonrası gerekli belgelerinde eksiklik yoksa, başvuru onayından sonra kurumda Akreditasyon Kurulu tarafından yerinde değerlendirme yapılır.",
  accreditation:
    "Yerinde değerlendirme sonrası hazırlanan rapor Akreditasyon kurulu tarafından en geç 1 ay içinde karara bağlanır. Yerinde değerlendirme sonrası eksiklikler saptanmışsa ilgili NDB’ye 1 aya kadar süre verilerek eksikliklerin tamamlanması istenir. Bu süre sonunda rapor üzerinden ya da gerek görülürse tekrar yerinde değerlendirme yapılarak akreditasyon süreci sonuçlandırılır.",
  re_evaluation:
    "KEPAN Akreditasyon Kurulu eksikliklerin tamamlanması için NDB tarafından sunulan raporu inceledikten sonra Akreditasyon kararı alabilir ya da 2 ay içinde yeniden yerinde değerlendirme isteyebilir.",
  accreditation_reject: "Akreditasyon reddedildi.",
  waiting_date: "Yeniden değerlendirme için tarih bekleniyor.",
  board_approval:
    "Tüm süreçlerin tamamlanması sonrası kurum akredite olur. Akreditasyon süresi 5 yıldır. Kurum akreditasyon sürecinin bitmesine 6 ay kala tekrar akreditasyon sürecini başlatmak zorundadır. Aksi takdirde akreditasyon süresi uzatılmaz.",
  board_reject: "Kurul tarafından reddedildi.",
  application_cancel: "Akreditasyon başvurusu iptal edildi.",
};

const LocationModal = ({ isOpen, toggle, status, step, visitStatus }) => {
  const svgRef = useRef();
  const [activeStep, setActiveStep] = useState(null);
  const [highlightedLinks, setHighlightedLinks] = useState(new Set());

  // Modal kapandığında state'leri sıfırla
  useEffect(() => {
    if (!isOpen) {
      setActiveStep(null);
      setHighlightedLinks(new Set());
    }
  }, [isOpen]);

  // Status değiştiğinde yolları güncelle
  useEffect(() => {
    if (!status) return;
    
    if (status && isOpen) {
      let stepId;
      
      // Step 2 için özel kontrol
      if (step === 2) {
        if (visitStatus && visitStatus === 'REVIEW_COMPLETED') {
          switch (status) {
            case "REJECTED":
              stepId = "board_reject";
              break;
            case "CONFIRMED":
              stepId = "board_approval";
              break;
            case "MEMBER_CONFIRMATION":
              stepId = "accreditation";
              break;
            case "REVISIT_WAITING_DATE_SELECTION":
              stepId = "re_evaluation";
              break;
            default:
              stepId = "application";
          }
        } else {
          switch (status) {
            case "NEW":
              stepId = "application";
              break;
            case "SEND":
              stepId = "application";
              break;
            case "REJECTED":
              stepId = "final_reject";
              break;
            case "REVISIT_REQUESTED":
              stepId = "re_evaluation";
              break;
            case "REVISION":
              stepId = "revise";
              break;
            case "CONFIRMED":
              stepId = "board_approval";
              break;
            case "MEMBER_CONFIRMATION":
              stepId = "accreditation";
              break;
            case "READY_TO_VISIT":
              stepId = "on_site_eval";
              break;
            case "REVISIT_WAITING_DATE_SELECTION":
              stepId = "re_evaluation";
              break;
            case "REVISION_REPORT_REQUESTED":
              stepId = "revise";
              break;
            case "WAITING_VISIT_PLANNING":
              stepId = "on_site_eval";
              break;
            case "CANCELED":
              stepId = "application_cancel";
              break;
            default:
              stepId = "application";
          }
        }
      } else {
        // Normal akış için mevcut switch case
        switch (status) {
          case "NEW":
            stepId = "hospital_register";
            break;
          case "CANCELED":
            stepId = "hospital_cancel";
            break;
          case "CONFIRMED":
            stepId = "application";
            break;
          case "REJECTED":
            stepId = "hospital_reject";
            break;
          case "READY_TO_VISIT":
            stepId = "re_evaluation";
            break;
          case "CANCELED":
            stepId = "hospital_reject";
            break;
          case "MEMBER_CONFIRMATION":
            stepId = "accreditation";
            break;
          case "REVISIT_WAITING_DATE_SELECTION":
            stepId = "waiting_date";
            break;
          case "WAITING_VISIT_PLANNING":
            stepId = "on_site_eval";
            break;
          default:
            stepId = "hospital_register";
        }
      }

      setActiveStep(stepId);

      // Yolu belirle
      const path = new Set();
      let currentStep = stepId;
      
      if (step === 2 && stepId) {
        path.add("application");
      }

      while (currentStep) {
        path.add(currentStep);
        const parentLink = links.find((link) => link.to === currentStep);
        currentStep = parentLink ? parentLink.from : null;
      }
      
      setHighlightedLinks(path);
    }
  }, [isOpen, status, step, visitStatus]);

  useEffect(() => {
    if (!isOpen) return;

    const width = 700;
    const height = 550;

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("display", "block")
      .style("margin", "auto")
      .append("g")
      .attr("transform", "translate(50,50)");

    const positions = {
      hospital_register: { x: 200, y: 0 },
      hospital_reject: { x: 100, y: 70 },
      hospital_cancel: { x: 200, y: 70 },
      application: { x: 350, y: 70 },
      final_reject: { x: 100, y: 150 },
      revise: { x: 300, y: 150 },
      final_approved: { x: 400, y: 150 },
      application_cancel: { x: 200, y: 150 },
      on_site_eval: { x: 400, y: 200 },
      accreditation: { x: 150, y: 270 },
      re_evaluation: { x: 300, y: 270 },
      accreditation_reject: { x: 450, y: 270 },
      board_approval: { x: 50, y: 370 },
      board_reject: { x: 150, y: 370 },
    };

    // Önce okları çiz
    links.forEach((link, index) => {
      if (!positions[link.from] || !positions[link.to]) return;

      const from = positions[link.from];
      const to = positions[link.to];
      const dx = 0;

      svg
        .append("path")
        .attr(
          "d",
          `M ${from.x},${from.y + 10} L ${from.x + dx},${
            (from.y + to.y) / 2
          } L ${to.x + dx},${(from.y + to.y) / 2} L ${to.x},${to.y - 20}`
        )
        .attr("stroke", "black") // Tüm oklar siyah
        .attr("fill", "none")
        .attr("stroke-width", 2)
        .attr("marker-end", "url(#arrow)")
        .attr("class", `link-${link.to}`);
    });

    // Ok uçları için tanımlamalar
    const defs = svg.append("defs");

    // Siyah ok ucu
    defs
      .append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", "5")
      .attr("refY", "5")
      .attr("markerWidth", "6")
      .attr("markerHeight", "6")
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", "black");

    // Yeşil ok ucu
    defs
      .append("marker")
      .attr("id", "greenArrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", "5")
      .attr("refY", "5")
      .attr("markerWidth", "6")
      .attr("markerHeight", "6")
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", "#28a745");

    // Sonra kutuları ve metinleri çiz
    steps.forEach((step) => {
      const pos = positions[step.id];
      if (!pos) return;

      
      if (step.id === activeStep) {
        // Aktif adım için siyah kutu
        svg
          .append("rect")
          .attr("x", pos.x - step.text.length * 5)
          .attr("y", pos.y - 15)
          .attr("width", step.text.length * 10)
          .attr("min-width", 100)
          .attr("height", 30)
          .attr("rx", 5)
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 2);
      } else if (highlightedLinks.has(step.id)) {
        // Aktif adıma giden yoldaki adımlar için yeşil kutu
        svg
          .append("rect")
          .attr("x", pos.x - step.text.length * 4)
          .attr("y", pos.y - 15)
          .attr("width", step.text.length * 8)
          .attr("min-width", 150)
          .attr("height", 30)
          .attr("rx", 5)
          .attr("fill", "none")
          .attr("stroke", "#28a745")
          .attr("stroke-width", 2);
      }

      // Metni çiz
      svg
        .append("text")
        .attr("x", pos.x)
        .attr("y", pos.y)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("fill", "black")
        .style("font-size", "16px")
        .style("font-weight", "bold")
        .text(step.text);

      
    });
  }, [isOpen, status, activeStep]);

  const handleClose = () => {
    toggle();
    setActiveStep(null);
    setHighlightedLinks(new Set());
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
      style={{ maxWidth: "85%", width: "80%" }}
    >
      <ModalBody className="text-center">
        <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>
          Hastane Akreditasyon Süreci
        </h4>
        <div style={{ display: "flex", gap: "20px" }}>
          {/* Sol taraf - SVG diyagram */}
          <div
            style={{
              maxHeight: "70vh",
              width: "45%",
              alignItems: "center",
            }}
          >
            <svg ref={svgRef}></svg>
          </div>

          {/* Sağ taraf - Aktif adım mesajı */}
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              padding: "30px",
              backgroundColor: "#f8f9fa",
              borderRadius: "8px",
              border: "1px solid #dee2e6",
              minWidth: "300px",
              marginTop: "50px",
              marginRight: "50px",
              height: "fit-content",
              alignSelf: "flex-start"
            }}
          >
            <h5 style={{ fontWeight: "bold", marginBottom: "15px" }}>
              {(steps.find((step) => step?.id === activeStep) || {}).text || ""}
            </h5>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#495057",
                margin: 0,
              }}
            >
              {stepMessages[activeStep] || ""}
            </p>
          </div>
        </div>
        <Button
          color="danger"
          className="mt-3 px-4 py-2"
          onClick={handleClose}
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          Kapat
        </Button>
      </ModalBody>
    </Modal>
  );
};

LocationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  status: PropTypes.string,
  step: PropTypes.number,
  visitStatus: PropTypes.string
};

LocationModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  status: 'NEW',
  step: 1,
  visitStatus: null
};

export default LocationModal;
