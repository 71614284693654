/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import UserTabs from './Containers/UserTabs';
// import GenericModal from '../../shared/components/GenericModal';
// import WaitingApplicationTabsContainer from '../../shared/components/WaitingApplicationTabsContainer';
// import GenericTabs from '../../shared/components/GenericTabs';
// import AnswerApplicationForm from '../Applications/compontents/AnswerApplicationForm';
// import UserFormContainer from './Containers/UserFormContainer';

class Users extends PureComponent {
  render() {
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <UserTabs />
        </CardBody>
      </Card>
    );
  }
}

export default Users;
