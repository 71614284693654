/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable */
/* eslint-disable block-scoped-var */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
function validateTckn(value) {
  value = value.toString();
  const isEleven = /^[0-9]{11}$/.test(value);
  let totalX = 0;
  for (var i = 0; i < 10; i++) {
    totalX += Number(value.substr(i, 1));
  }
  const isRuleX = totalX % 10 == value.substr(10, 1);
  let totalY1 = 0;
  let totalY2 = 0;
  for (var j = 0; j < 10; j += 2) {
    totalY1 += Number(value.substr(i, 1));
  }
  for (var k = 1; k < 10; k += 2) {
    totalY2 += Number(value.substr(i, 1));
  }
  const isRuleY = ((totalY1 * 7) - totalY2) % 10 == value.substr(9, 0);
  return isEleven && isRuleX && isRuleY;
}

const tcknValidate = (value) => (value !== null && value !== undefined ? (validateTckn(value) ? '' : 'Tc kimlik numarası hatalı') : undefined);

const letter = (value) => (value && !/^[a-zA-ZığüşöçİĞÜŞÖÇ a-zA-ZığüşöçİĞÜŞÖÇ]+$/i.test(value) ? 'Bu alanda sadece harf kullanılabilir' : undefined);

const number = (value) => (value && !/^[1-9]\d*$/i.test(value) ? 'Bu alanda sadece sayı kullanılabilir' : undefined);

const required = (value) => (value ? undefined : 'Bu alan zorunludur');

const required_with_zero = (value) => ((value || value === 0) ? undefined : 'Bu alan boş bırakılmamalıdır');

const required_array = (value) => ((value && value.length) ? undefined : 'Bu alan boş bırakılmamalıdır');

const email = (value) => (value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value) ? 'Geçersiz e-posta adresi' : undefined);

const url = (value) => (value && !/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i.test(value) ? 'Geçersiz URL' : undefined);

// const maxLength = max => value => (value && value.length > max ? i18next.languages[0] === 'en' ? `Must be ${max} characters or less` : `${max} karakter veya daha kısa olmalı` : undefined);

const min_length = (value) => (value && value.length < 10 ? 'En az 10 karakter girilmelidir' : undefined);

const max_180 = (value) => (value && value > 180 ? '180 günden fazla eklenemez.' : undefined);

const phoneNumber = (value) => (value && !/^[(]{1}[0-9]{3}[)]{1}\s[0-9]{3}[-]{1}[0-9]{4}$/i.test(value) ? 'Geçersiz telefon numarası, 10 rakam olmalıdır' : undefined);

const equal_password = (value, previousValue, allValues) => (value && allValues.values.rePassword && value !== allValues.values.password ? 'Şifreler eşleşmiyor' : undefined);

const citizen_required = (value, previousValue, allValues) => (value && allValues.values.citizenDto && allValues.values.citizenDto.id ? undefined : 'Vatandaş bulunamadı');

const atLeastOnePhone = (value, previousValue, allValues) => (
  ((allValues.values.homePhone || allValues.values.workPhone || allValues.values.cellPhone) && ((allValues.values.homePhone && !allValues.values.homePhone.endsWith('_')) || (allValues.values.workPhone && !allValues.values.workPhone.endsWith('_')) || (allValues.values.cellPhone && !allValues.values.cellPhone.endsWith('_')))) ? undefined : 'En az bir telefon numarası girmelisiniz');
const role_or_privilege_required = (value, allValues) => (((allValues.roleDtoSet && allValues.roleDtoSet.length > 0) || (allValues.privilegeDtoSet && allValues.privilegeDtoSet.length > 0)) ? undefined : 'En az bir Rol ya da Yetki eklenmelidir.');

// const date = value => (value && checkDate('01/01/2019', value) ? i18next.languages[0] === 'en' ? 'Please enter a valid date' : 'Lütfen geçerli bir tarih giriniz' : undefined);

// const date_now = value => (value && checkDateNow(new Date(), value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be earlier than today' : 'Seçilen tarih bugünden erken olamaz' : undefined);

// const greater_date = (value, previousValue, allValues) => (value && allValues.values.postingDate && checkDate(allValues.values.postingDate, value) ? i18next.languages[0] === 'en' ? 'Expiration date can not be earlier than the posting date' : 'Sona erme tarihi oluşturulma tarihinden erken olamaz' : undefined);

// const startPostingDate = (value, previousValue, allValues) => (value && allValues.values.startPostingDate && checkDate(allValues.values.startPostingDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start posting date' : 'Seçilen tarih başlangıç tarihinden önce olamaz' : undefined);

// const startExpirationDate = (value, previousValue, allValues) => (value && allValues.values.startExpirationDate && checkDate(allValues.values.startExpirationDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start expiration date' : 'Seçilen tarih bitiş tarihinden önce olamaz' : undefined);

// const end_date = (value, previousValue, allValues) => (value && allValues.values.startDate && checkDate(allValues.values.startDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start date' : 'Seçilen tarih başlangıç tarihinden önce olamaz' : undefined);

// const end_date_without_equal = (value, previousValue, allValues) => (value && allValues.values.startDate && checkDateWithoutEqual(allValues.values.startDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start date' : 'Seçilen tarih başlangıç tarihinden önce olamaz' : undefined);

// const expiration_date = (value, previousValue, allValues) => (value && allValues.values.startDate && checkDate(allValues.values.postingDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before expiration date' : 'Seçilen tarih son başvuru tarihinden önce olamaz' : undefined);

// const expiration_date_without_equal = (value, previousValue, allValues) => (value && allValues.values.startDate && checkDateWithoutEqual(allValues.values.postingDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before expiration date' : 'Seçilen tarih son başvuru tarihinden önce olamaz' : undefined);

// const end_time = (value, previousValue, allValues) => (value && allValues.values.startTime && checkDate(allValues.values.startTime, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start time' : 'Seçilen tarih başlangıç zamanından önce olamaz' : undefined);

// const end_hour = (value, previousValue, allValues) => (value && allValues.values.startTime && checkHour(allValues.values.startTime, value) ? i18next.languages[0] === 'en' ? 'Start time and end time are not the same' : 'Seçilen zaman başlangıç zamanından önce olamaz' : undefined);

// const same_hour = (value, previousValue, allValues) => (value && allValues.values.startTime && checkSameHour(allValues.values.startTime, value) ? i18next.languages[0] === 'en' ? 'Slot Start Time and Slot End Time cannot be the same. Please change the Slot End time.' : 'Slot Başlangıç Zamanı ve Slot Bitiş Zamanı aynı olamaz. Lütfen Slot Bitiş zamanını değiştiriniz.' : undefined);

// const minBirthDate = (value, previousValue, allValues) => (value && allValues.values.minBirthDate && checkDate(allValues.values.minBirthDate, value) ? i18next.languages[0] === 'en' ? 'Selected date cannot be before start date' : 'Seçilen tarih başlangıç tarihinden önce olamaz' : undefined);

export const rules = {
  required,
  min_length,
  required_with_zero,
  email,
  phoneNumber,
  letter,
  number,
  url,
  required_array,
  tcknValidate,
  equal_password,
  citizen_required,
  atLeastOnePhone,
  role_or_privilege_required,
  max_180,
};
