/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { Field, reduxForm, initialize, FormSection, FieldArray, reset, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import CheckboxSection from '../../../shared/components/generic-form/FormSections/SingleSections/CheckboxSection';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import MaskedInputSection from '../../../shared/components/generic-form/FormSections/SingleSections/MaskedInputSection';
import { getById } from '../../../redux/actions/byIdAction';

class UserForm extends PureComponent {
  componentDidMount(prevProps) {
    const { id, getCouncilById, getExternalVisitorById, isCouncil, isExternal } = this.props;
    if (id) {
      if (isCouncil) {
        getCouncilById(id);
      }
      if (isExternal) {
        getExternalVisitorById(id);
      }
    }
  }

  render() {
    const {
      initialValues, formLoading, isCouncil, mode,
      pristine, submitting, handleSubmit, id, // redux-form props
    } = this.props;
    return (
      <FormWithLoading loading={formLoading} className="form form--horizontal" onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <h5 style={{ borderBottom: '1px solid #007c85', paddingBottom: '0.75rem' }}>Yeni Üye</h5>
          </Col>
          <Col xs={12} lg={6}>
            <InputSection
              label="Adı"
              name="name"
              type="text"
              validate={['required']}
              mode={mode}
              initialValues={initialValues}
            />
          </Col>
          <Col xs={12} lg={6}>
            <InputSection
              label="Soyadı"
              name="surname"
              type="text"
              validate={['required']}
              mode={mode}
              initialValues={initialValues}
            />
          </Col>
          <Col xs={12} lg={6}>
            <InputSection
              label="Unvan"
              name="title"
              type="text"
                // validate={['required']}
              mode={mode}
              initialValues={initialValues}
            />
          </Col>
          <Col xs={12} lg={6}>
            <InputSection
              label="Eposta"
              name="email"
              type="email"
              validate={['required']}
              mode={mode}
              isDisabled={!!id}
              initialValues={initialValues}
            />
          </Col>
          {isCouncil &&
            <Col xs={12} lg={6}>
              <CheckboxSection
                label="Başkan mı?"
                name="president"
              // defaultChecked
                mode={mode}
                initialValues={initialValues}
              />
            </Col>}

        </Row>

        {mode !== 'view' &&
        <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '1rem' }}>
          <Button type="submit" disabled={pristine || submitting} color="success">Kaydet</Button>
        </ButtonToolbar>}
      </FormWithLoading>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCouncilById(id) {
    dispatch(getById('council/member', id));
  },
  getExternalVisitorById(id) {
    dispatch(getById('external/visitor', id));
  },
});
const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.id ? (ownProps.isExternal ? state.externalVisitorById.data : state.councilMemberById.data) : {},
});
UserForm = reduxForm({
  form: 'User_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(UserForm);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserForm));
