/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */
/* eslint-disable  no-unneeded-ternary */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// import ReactTooltip from '../../../../../../node_modules/react-tooltip';
import { rules } from '../validate';

const renderTextArea = ({
  input, maxLength, label, placeholder, type, mask, isDisabled, meta: { touched, error } }) => (
    <div className="form__form-group-input-wrap">
      <textarea {...input} className="input_class" rows="5" placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

class TextAreaSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    initialValues: PropTypes.any,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    submessage: PropTypes.string,
    maxLength: PropTypes.number,
    character: PropTypes.string,
    validate: PropTypes.array,
    noLabelBool: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: undefined,
    label: undefined,
    submessage: undefined,
    validate: [],
    character: undefined,
    maxLength: 50000,
    noLabelBool: false,
  };

  render() {
    const { t, mode, initialValues, name, label, submessage, maxLength, character, validate, noLabelBool } = this.props;

    return (
      <div className="form__form-group">
        {!noLabelBool &&
        <span className="form__form-group-label">{label ? label : ''}
          {/* {mode !== 'view' && submessage &&
            <span style={{ paddingLeft: '4px', color: t('tooltips.icon_color') }} data-tip={submessage} data-background-color={t('tooltips.background_color')} data-place="right" data-for={submessage}><i className={t('tooltips.class')} /><ReactTooltip id={submessage} /></span>
          } */}
        </span>}
        <div className="form__form-group-field">
          {mode !== 'view' ?
            <Field
              name={name}
              component={renderTextArea}
              validate={validate && validate.map(rule => rules[rule])}
              maxLength={maxLength}
            />
            :
            (initialValues !== undefined && initialValues[name] !== undefined) ? <div className=""> {initialValues[name]} </div> : <p>-</p>}
        </div>
        {mode !== 'view' && maxLength &&
        <span className="form__form-group-character" style={{ fontSize: '10px', float: 'right' }}>
            {`En fazla ${maxLength} karakter`}
        </span>}
      </div>
    );
  }
}

export default TextAreaSection;
