/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Card, CardBody, Col,
} from 'reactstrap';
import { Chip, MenuItem } from '@material-ui/core';
import Select from 'react-select';
import Table from '../../shared/components/generic-table/Table';
import { appealStatus, appealStatusOptionList, tableFilterStyles } from '../App/helper/statusTableLookupValues';
import GenericModal from '../../shared/components/GenericModal';
import AnswerApplicationForm from '../Applications/compontents/AnswerApplicationForm';
import { getAppeal, postAppeal } from '../../redux/actions/appealAction';
import WaitingApplicationTabsContainer from '../Applications/Containers/WaitingApplicationTabsContainer';

class WaitingApplicationsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      selectedRowData: undefined,
      prevSelectedRowId: undefined,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedRowId,
    } = this.state;
  }

  setSelectedRow = (rowData) => {
    const { pagePrivileges, history } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        selectedRowData: rowData,
        prevSelectedRowId: rowData.id,
      });
      history.push({
        pathname: '/application/details',
        state: { appealId: rowData.id },
      });
    }
  };

  render() {
    const {
      tableData, setSelectedRow, tabs, changeTab, currentTabIndex, fetchTableData, history, listAppeal, idAppeal,
    } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId, selectedRowData,
    } = this.state;
    
    const confirmedHospitals = { 
      ...listAppeal, 
      tableData: listAppeal.data?.filter(el => el.status === 'CONFIRMED') || [],
    };
    return (
      <div>
        <Table
          data={confirmedHospitals}
          newButtonBool={false}
          refreshButtonBool
          refreshButtonAction={() => fetchTableData()}
          columns={[
            { title: 'Başvuru No', field: 'code' },
            { title: 'Hastane', field: 'hospitalDto.title' },
            { title: 'Başvuru Zamanı', field: 'appealTime' },
            {
              title: 'Durum',
              field: 'status',
              lookup: appealStatus,
              filterComponent: ({ columnDef, onFilterChanged }) => (
                <Select
                  options={Object.entries({ '': <Chip className="tableChip bg-primary" size="small" label="Tümü" />, ...appealStatus }).map((el) => ({ value: el[0], label: el[1] }))}
                  styles={tableFilterStyles}
                  placeholder="Durum"
                  onChange={(e) => {
                    // Calling the onFilterChanged with the current tableId and the new value
                    onFilterChanged(columnDef.tableData.id, e.value);
                  }}
                />
              ),
            },
          ]}
          setSelectedRow={this.setSelectedRow}
          selectedRowId={selectedRowId}
          prevSelectedRowId={prevSelectedRowId}
          changeRowsPerPage={this.changeRowsPerPage}
          changePage={this.changePage}
          hasTabs
          tabButtons={tabs}
          changeTab={changeTab}
          currentTab={currentTabIndex}
          maxBodyHeight={false}
        />
        <GenericModal
          isModalOpen={isModalOpen}
          button={<Button className="d-none my-2" style={{ width: 0 }} />}
          toggleModal={this.toggle}
          onClosed={() => this.setSelectedRow(null)}
          content={(
            <div>
              <WaitingApplicationTabsContainer
                initialValues={idAppeal}
                component={AnswerApplicationForm}
                title="Başvuru Detayları"
              />
            </div>
              )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(getAppeal('list'));
  },
  getAppeal(id) {
    dispatch(getAppeal('id', id));
  },
});
const mapStateToProps = (state) => ({
  listAppeal: state.listAppeal,
  idAppeal: state.idAppeal.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaitingApplicationsTable));
