/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable vars-on-top */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-var */
/* eslint-disable no-shadow */
/* eslint-disable quote-props */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { stopSubmit } from 'redux-form';
import { addNotification } from './notificationActions';
import { authHeader } from '../../containers/App/helper/auth-header';
import { removeToken, createToken } from '../../containers/App/helper/auth-token';
// import { userRolesControl } from '../../containers/App/helper/auth-user-info';
// import { userProfile } from './userProfileAction';
// import { userProfile } from './userProfileAction';
// import { userRolesControl } from '../../containers/App/helper/auth-user-info';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';
export const LOGOUT = 'LOGOUT';

// configLang();

function request() {
  return {
    type: LOGIN_REQUEST,
  };
}

function success(user, token) {
  return {
    type: LOGIN_SUCCESS,
    user,
    token,
  };
}

function failure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

function tokenRefreshRequest() {
  return {
    type: TOKEN_REFRESH_REQUEST,
  };
}

function tokenRefreshSuccess(token) {
  return {
    type: TOKEN_REFRESH_SUCCESS,
    token,
  };
}

function tokenRefreshFailure(error) {
  return {
    type: TOKEN_REFRESH_FAILURE,
    error,
  };
}

function userLogout() {
  return {
    type: LOGOUT,
  };
}

export function login(history, fromPath, values) {
  const loginData = values;
  if (loginData['remember_me-log_in_form'] !== undefined) {
    delete loginData['remember_me-log_in_form'];
  }
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Expose-Headers": "Access-Token",
    },
  };
  return dispatch => {
    dispatch(request());
    axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, loginData, axiosConfig)
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification('user_incorrect', 'error'));
        }
        if (res.data.token !== undefined) {
          const token = res.data.token.replace("Bearer ", "");
          const decoded = jwtDecode(token);
          const user = JSON.stringify(decoded);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('userInfo', user);
          dispatch(success(user, token));
          dispatch(success(JSON.stringify(decoded, token)));
          dispatch(stopSubmit('Login_Form'));
        } else {
          dispatch(failure());
          dispatch(stopSubmit('Login_Form'));
        }
      })
      .then(() => {
        history.push('/home');
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(stopSubmit('Login_Form'));
        if (error.response && error.response.data !== undefined) {
          dispatch(addNotification(error.response.data.errorMessage, 'error'));
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}

export function refreshToken(dispatch) {
  dispatch(tokenRefreshRequest());
  var refreshTokenPromise = axios.get(`${process.env.REACT_APP_API_URL}/user/refresh`, authHeader())
    .then(res => {
      if (res.data.errorMessage) {
        dispatch(addNotification(res.data.errorMessage, 'error'));
      } else {
        removeToken();
        createToken(res.data.token);
        dispatch(tokenRefreshSuccess(res.data.token));
      }
    })
    .catch((error) => {
      dispatch(tokenRefreshFailure());
      if (error.response !== undefined) {
        dispatch(addNotification(error.response.status, 'error'));
      } else {
        dispatch(addNotification('500', 'error'));
      }
    });
  return refreshTokenPromise;
}

export function refreshSession(dispatch) {
  dispatch(tokenRefreshRequest());
  var refreshTokenPromise = axios.get(`${process.env.REACT_APP_API_URL}/session/refresh`, authHeader())
    .then(res => {
      if (res.data.errorCode) {
        dispatch(addNotification('user_incorrect', 'error'));
      }
      if (res.data.token !== undefined) {
        removeToken();
        const token = res.data.token.replace("Bearer ", "");
        const decoded = jwtDecode(token);
        const user = JSON.stringify(decoded);
        createToken(res.data.token);
        // localStorage.setItem('token', res.data.token);
        localStorage.setItem('userInfo', user);
        dispatch(tokenRefreshSuccess(JSON.stringify(decoded, token)));
        // dispatch(stopSubmit('Login_Form'));
      } else {
        dispatch(tokenRefreshFailure());
        // dispatch(stopSubmit('Login_Form'));
      }
    })
    .catch((error) => {
      dispatch(tokenRefreshFailure());
      // dispatch(stopSubmit('Login_Form'));
      if (error.response !== undefined) {
        dispatch(addNotification('user_incorrect', 'error'));
      } else {
        dispatch(addNotification('500', 'error'));
      }
    });
  return refreshTokenPromise;
}

export function logout() {
  removeToken();
  localStorage.removeItem('userInfo');
  return dispatch => {
    dispatch(userLogout());
  };
}

export function logoutMiddleware(dispatch) {
  removeToken();
  localStorage.removeItem('userInfo');
  dispatch(userLogout());
  window.location.href = '/login';
}
