/* eslint-disable react/static-property-placement */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderDateTimePickerField from '../../../form/DateTimePicker';
import { rules } from '../validate';

class DateTimeFormSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    initialValues: PropTypes.any,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    validate: PropTypes.array,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    dateFormat: PropTypes.string,
    showMonthYearPicker: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    scrollableYearDropdown: PropTypes.bool,
    submessage: PropTypes.string,
    excludePastTimesBool: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: undefined,
    minDate: undefined,
    maxDate: undefined,
    dateFormat: 'dd/MM/yyyy',
    showMonthYearPicker: false,
    showYearDropdown: false,
    scrollableYearDropdown: false,
    validate: [],
    submessage: undefined,
    excludePastTimesBool: false,
  };

  render() {
    const {
      t, mode, initialValues, name, label, placeholder, minDate, maxDate, disableTime,
      dateFormat, showMonthYearPicker, showYearDropdown, scrollableYearDropdown, validate, submessage, excludePastTimesBool,
    } = this.props;
    return (
      <>
        <div className="form__form-group">
          <span className="form__form-group-label">{label}
          </span>
          <div className="form__form-group-field">
            {mode !== 'view' ?
              <>
                <Field
                  name={name}
                  placeholder={placeholder}
                  component={renderDateTimePickerField}
                  minDate={minDate && minDate}
                  maxDate={maxDate && maxDate}
                  editmode={mode === 'edit'}
                  dateFormat={dateFormat}
                  disableTime={disableTime}
                  showMonthYearPicker={showMonthYearPicker}
                  showYearDropdown={showYearDropdown}
                  scrollableYearDropdown={scrollableYearDropdown}
                  validate={validate && validate.map(rule => rules[rule])}
                  excludePastTimesBool={excludePastTimesBool}
                />
              </>
              :
              (initialValues !== undefined && initialValues[name] !== undefined) ? (initialValues[name]) : <p>-</p>}
          </div>
        </div>
      </>
    );
  }
}

export default ((DateTimeFormSection));
