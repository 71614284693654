/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import DateTimeFormSection from '../../../shared/components/generic-form/FormSections/SingleSections/DateTimeFormSection';
import MultiSelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSections/MultiSelectSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import { list } from '../../../redux/actions/listAction';

class OnSiteMeetingDateAndTeamSelectForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      maxExternalMember: 3,
      maxCouncilMember: 3,
    };
  }
  componentDidMount() {
    const { getExternalVisitors, getCouncilMembers } = this.props;
    getExternalVisitors();
    getCouncilMembers();
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, tcNo, id, selectedOthers, selectedUsers } = this.props;
  }

  render() {
    const {
      pristine, submitting, toggle, reset, handleSubmit, initialValues, // redux-form props
      councilMemberList, formLoading, title, externalVisitorList, selectedUsers, selectedOthers, startDate, endDate,
    } = this.props;
    const allOptions = [...(externalVisitorList || []), ...(councilMemberList || [])].map((user) => {
      return {
        id: user?.id, 
        value: user?.id, 
        label: `${user?.title || ''} ${user?.name || ''} ${user?.surname || ''}`.trim()
      };
    });
    const selectedIds = [
      ...(selectedOthers || []), 
      ...(selectedUsers || [])
    ].map(item => item?.id).filter(Boolean);
    const filteredOptions = allOptions.filter(item => 
      item?.id && Array.isArray(selectedIds) ? selectedIds.includes(item.id) : false
    );
    
    console.table('filteredOptions', filteredOptions, 'selectedIds', selectedIds);
    return (
      <Col md={12} lg={12}>
        <h5>{title}</h5>
        <FormWithLoading loading={formLoading} className="form form--horizontal px-2">
          <Row>
            <h6>Hastanenin Başvuru Sürecinde Müsait Olduğunu Belirttiği Tarihler</h6>
            <i>{startDate} - {endDate}</i>
            <Col xs={12} className="mb-2">
              <DateTimeFormSection
                name="visitDate"
                label="Hastanenin uygun tarihlerinden birini seçiniz"
                placeholder="Uygun Tarih Seçiniz"
                disableTime={true}
                minDate={initialValues.status !== 'REVISIT_REQUESTED' ? startDate : null}
                maxDate={initialValues.status !== 'REVISIT_REQUESTED' ? endDate : null}
                validate={['required']}
              />
            </Col>
            {initialValues.status !== 'REVISIT_WAITING_DATE_SELECTION' && (
            <>
              <Col xs={6}>
                <MultiSelectSection
                  label="Akreditasyon Kurulu Üyeleri"
                  name="councilMembers"
                  items={councilMemberList && councilMemberList.map((councilMember) => {
                    return {id: councilMember.id, value: councilMember.id, label: councilMember.name + ' ' + councilMember.surname + ' (' + councilMember.title + ')' };
                  })}
                  max={3}
                />
              </Col>
              <Col xs={6}>
                <MultiSelectSection
                  label=" Dış Değerlendirici"
                  name="externalMembers"
                  items={externalVisitorList && externalVisitorList.map((externalVisitor) => {
                    return {
                      id: externalVisitor.id, value: externalVisitor.id, label: externalVisitor.title + ' ' + externalVisitor.name + ' ' + externalVisitor.surname,
                    };
                  })}
                  max={3}
                />
              </Col>
              <Col xs={12}>
                <SelectSection
                  label="Seçilen Üyeler Arasından Ekip Başkanı Seçiniz"
                  name="headOfCommitteeId"
                  placeholder="Seçiniz"
                  options={filteredOptions}
                  validate={['required']}
                />
              </Col>
            </>
            )}
          </Row>

          <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
            <>
              <Row className="w-100 mt-2">
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                </Col>
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} onClick={() => toggle()} color="secondary" size="sm">İptal</Button>
                </Col>
              </Row>
            </>
          </ButtonToolbar>
        </FormWithLoading>
      </Col>
    );
  }
}
const selector = formValueSelector('Select_Available_Dates_Form');

const mapDispatchToProps = (dispatch) => {
  return {
    getExternalVisitors() {
      dispatch(list('external/visitor'));
    },
    getCouncilMembers() {
      dispatch(list('council/member'));
    },

  };
};
const mapStateToProps = (state, ownProps) => ({
  selectedUsers: selector(state, 'councilMembers') || [],
  selectedOthers: selector(state, 'externalMembers') || [],
  externalVisitorList: state.externalVisitorList.data,
  councilMemberList: state.councilMemberList.data,

});

OnSiteMeetingDateAndTeamSelectForm = reduxForm({
  form: 'Select_Available_Dates_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(OnSiteMeetingDateAndTeamSelectForm);

export default connect(mapStateToProps, mapDispatchToProps)(OnSiteMeetingDateAndTeamSelectForm);
