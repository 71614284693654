/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-lonely-if */
import axios from 'axios';
import { authHeader } from '../../containers/App/helper/auth-header';
// import store from '../../../containers/App/store';
// import { authHeader } from '../../../containers/App/helper/auth-header';
import { addNotification } from './notificationActions';

function request(namespace) {
  return {
    type: `${namespace}/SAVE_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/SAVE_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/SAVE_FAILURE`,
    error,
  };
}

export function save(namespace, values) {
  if (values && values.tableData) {
    delete values.tableData;
  }
  // if (values && values.id === undefined && namespace !== "feedbacktype" && namespace !== "feedbackstatus" && namespace !== "topicgroup" && namespace !== "role" && namespace !== "topic" && namespace !== "personnel" && namespace !== "personneltitle" && namespace !== "citizen" && namespace !== "request") {
  //   values = {
  //     ...values,
  //     id: 0,
  //   };
  // }
  const keys = Object.keys(values);
  keys.forEach((key) => {
    if (key.endsWith('_filter')) {
      delete values[key];
    }
  });
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/save`, values || {}, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          if (res.data.errorMessage) {
            dispatch(addNotification(res.data.errorMessage, 'error'));
          } else {
            dispatch(addNotification(res.data.errorCode, 'error'));
          }
        } else {
          dispatch(success(namespace, res));
          dispatch(addNotification('save_success', 'success'));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response && error.response.data !== undefined) {
          if (error.response.data.errorCode) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            } else {
              dispatch(addNotification(error.response.data.errorCode, 'error'));
            }
          } else {
            dispatch(addNotification(error.response.status, 'error'));
          }
        } else {
          dispatch(addNotification('unknown_error', 'error'));
        }
      });
  };
}
