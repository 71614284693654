/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import FormWithLoading from '../../shared/components/form/FormWithLoading';
import DateTimeFormSection from '../../shared/components/generic-form/FormSections/SingleSections/DateTimeFormSection';
import TextAreaSection from '../../shared/components/generic-form/FormSections/SingleSections/TextAreaSection';

class AccreditationConfirmForm extends PureComponent {
  componentDidUpdate(prevProps) {
    const { initialValues, initialize, personnelstatusList, form, change } = this.props;
  }

  render() {
    const {
      initialValues, formLoading, mode, pristine, submitting, handleSubmit, revisionRegister, rejectRegister, showTextArea,
    } = this.props;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <FormWithLoading loading={formLoading} className="form form--horizontal" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} className={revisionRegister ? 'text-warning' : (rejectRegister ? 'text-danger' : '')}>
                <h5 style={{ borderBottom: '1px solid #007c85', paddingBottom: '0.75rem' }}>{revisionRegister ? 'Başvuru Revizyon Mesajı' : (rejectRegister ? 'Kaydı Reddetme Mesajı' : '') }</h5>
              </Col>
              <Col xs={12} className="mb-2">
                <TextAreaSection
                  label="Karar Açıklaması Giriniz"
                  name="decisionDesc"
                  type="text"
                  validate={['required']}
                  mode={mode}
                  initialValues={initialValues}
                />
              </Col>
              <Col xs={12} className="mb-2">
                <DateTimeFormSection
                  name="confirmationDate"
                  label="Onay Tarihi Seçiniz"
                  disableTime={true}
                  validate={['required']}
                />
              </Col>
            </Row>
            <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '1rem' }}>
              <Button type="button" onClick={() => showTextArea('reset')} color="secondary" size="sm">Vazgeç</Button>
              <Button type="submit" disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
            </ButtonToolbar>
          </FormWithLoading>
        </CardBody>
      </Card>
    );
  }
}

AccreditationConfirmForm = reduxForm({
  form: 'Accredidate_Confirm_Form', // a unique identifier for this form
  enableReinitialize: true,
})(AccreditationConfirmForm);

export default (AccreditationConfirmForm);
