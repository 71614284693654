/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Row, Collapse,
} from 'reactstrap';
import { userRolesControl } from '../App/helper/auth-user-info';
import ApplicationsTabs from './compontents/ApplicationsTabs';

class Request extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentMode: 'create',
      selectedRowId: undefined,
      selectedRowData: undefined,
      currentTabIndex: 0,
    };
    this.tabsList = [
      { index: 0, text: 'Başvurular' },
      { index: 1, text: 'Yerinde Görüşmeler' },
    ];
  }

  componentDidUpdate() {
    // to do check if submit is success and close form with this.setSelectedRow(undefined)
  }

  changeTab = (newTabIndex) => {
    this.setState({ currentTabIndex: newTabIndex });
    this.setSelectedRow(undefined);
  }

  setSelectedRow = (rowData) => {
    const { pagePrivileges } = this.props;
    if (!rowData) {
      this.setState({
        currentMode: 'create',
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        currentMode: 'create',
        prevSelectedRowId: rowData.id,
        selectedRowId: rowData.id,
        selectedRowData: rowData,
      });
    }
  };

  render() {
    const {
      currentMode, selectedRowId, prevSelectedRowId,
      selectedRowData, currentTabIndex,
    } = this.state;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <Row>
            <ApplicationsTabs
              setSelectedRow={this.setSelectedRow}
              prevSelectedRowId={prevSelectedRowId}
              selectedRowId={selectedRowId}
              currentTabIndex={currentTabIndex}
              setCurrentTab={(tabIndex) => this.setCurrentTab(tabIndex)}
              tabsList={this.tabsList}
              changeTab={this.changeTab}
              pagePrivileges={this.props.pagePrivileges}
            />
          </Row>
        </CardBody>
      </Card>

    );
  }
}

export default (Request);
