/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable object-shorthand */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { stopSubmit } from 'redux-form';
import { authHeader } from '../../containers/App/helper/auth-header';
import { addNotification } from './notificationActions';

function request(namespace) {
  return {
    type: `${namespace}/REGISTER_REQUEST`,
  };
}

function success(namespace, data, res) {
  return {
    type: `${namespace}/REGISTER_SUCCESS`,
    data,
    res,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/REGISTER_FAILURE`,
    error,
  };
}


export function register(namespace, form, values) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/register/public/save`, values, authHeader())
      .then(res => {
        if (res.data.errorMessage) {
          dispatch(addNotification(res.data.errorMessage, 'error'));
        } else {
          dispatch(success(namespace, values, res));
        }
      })
      .catch(error => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data && error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          } else {
            dispatch(addNotification('register_error', 'error'));
            dispatch(stopSubmit(form));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}

export function registerUpdate(namespace, form, values, token) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/register/public/update/${token}`, values, authHeader())
      .then(res => {
        if (res.data.errorMessage) {
          dispatch(addNotification(res.data.errorMessage, 'error'));
        } else {
          dispatch(success(namespace, values, res));
        }
      })
      .catch(error => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data && error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          } else {
            dispatch(addNotification('register_error', 'error'));
            dispatch(stopSubmit(form));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}
