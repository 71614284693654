/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: null,
  success: false,
};

export const saveReducer = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/SAVE_REQUEST`: {
      return {
        ...initialState,
        data: {},
        loading: true,
        loaded: false,
        success: false,
      };
    }
    case `${namespace}/SAVE_SUCCESS`: {
      return {
        ...state,
        data: action.data,
        loading: false,
        loaded: true,
        success: true,
      };
    }
    case `${namespace}/SAVE_FAILURE`: {
      return {
        ...state,
        data: action.data,
        loading: false,
        loaded: true,
        success: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
