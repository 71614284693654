/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, CardHeader, Row, UncontrolledTooltip,
} from 'reactstrap';
import { getAction } from '../../redux/actions/getAction';
import { list } from '../../redux/actions/listAction';
import RequiredConditionsForNST from '../NutritionalSupportTeam/components/RequiredConditionsForNST';

class NutritionalMembersList extends PureComponent {
  componentDidMount() {
    const { fetchTableData, validateNutritionTeam } = this.props;
    fetchTableData();
    validateNutritionTeam();
  }

  render() {
    const {
      initialValues, confirmAction, routeToNst, toggle, nutritionMemberValidate,
    } = this.props;
    return (
      <Card>
        <CardHeader>Nütrisyon Ekibi</CardHeader>
        <CardBody className="p-0 p-md-2">
          <Alert className="mb-3" severity="info" style={{ }}>
            <AlertTitle>Nütrisyon ekibinizdeki üyeler aşağıda listelenmiştir. Başvuru oluştururken tüm üyeler başvurunuz içerisine dahil edilecektir.</AlertTitle>
            {nutritionMemberValidate.provided && (
            <AlertTitle>Eğer bilgilerde yanlışlık olduğunu düşünüyorsanız &apos;Nütrisyon Ekibini Düzenle&apos; butonuna tıklayarak ekip üyelerini düzenleyebilirsiniz.</AlertTitle>
            )}
            <ul className="mb-0" style={{ listStyle: 'none' }}>
              {initialValues && initialValues.data.length > 0 ? initialValues.data.map((member, index) => (
                <li key="index">{`${member.title} ${member.name} ${member.surname}`}</li>
              ))
                : <li className="text-danger">Henüz üye eklenmemiştir. &apos;Nütrisyon Ekibini Düzenle&apos; butonuna tıklayarak ekibinize üye ekleyin</li>}
            </ul>
          </Alert>

          <RequiredConditionsForNST
            initialValues={nutritionMemberValidate}
          />
          <Row className="mt-3">
            <ButtonToolbar className="mt-2 d-flex justify-content-around">
              <Button size="sm" className="btn-secondary" onClick={() => toggle()}> Vazgeç </Button>
              <Button size="sm" className="btn-custom-info" onClick={() => routeToNst()}> Nütrisyon Ekibini Düzenle </Button>
              <span id="newAppealBtn">
                <Button size="sm" className="btn-custom-success" disabled={!nutritionMemberValidate.provided} onClick={() => confirmAction()}> Başvuru Oluştur </Button>
              </span>
              {!nutritionMemberValidate.provided
              && <UncontrolledTooltip fade delay={{ show: 100, hide: 100 }} placement="bottom" target="newAppealBtn">Gerekli şartları sağlamadan başvuru oluşturamazsınız.</UncontrolledTooltip>}
            </ButtonToolbar>

          </Row>
        </CardBody>
      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(list('hospital/nutrition/member'));
  },
  validateNutritionTeam() {
    dispatch(getAction('hospital/nutrition/member/check'));
  },
});

const mapStateToProps = (state, ownProps) => ({
  initialValues: state.hospitalNutritionList,
  nutritionMemberValidate: state.nutritionMemberValidate.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(NutritionalMembersList);
