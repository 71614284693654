/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable padded-blocks */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { PureComponent } from 'react';
import VisitDetail from './containers/VisitDetail';

class index extends PureComponent {

  render() {
    const { history } = this.props;
    const { visitId, mode, realVisitId } = history.location.state;

    return (
      <VisitDetail history={history} id={(visitId && visitId)} realVisitId={(realVisitId && realVisitId)} mode={mode && mode}></VisitDetail>
    );
  }
}

export default index;
