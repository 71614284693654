/* eslint-disable react/no-did-update-set-state */
/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Card, CardBody, Col,
} from 'reactstrap';
import Table from '../../../shared/components/generic-table/Table';
import GenericModal from '../../../shared/components/GenericModal';
import UserForm from '../Components/UserForm';
import { list } from '../../../redux/actions/listAction';
import { save } from '../../../redux/actions/saveAction';
import { putAction } from '../../../redux/actions/putAction';

class UsersTableContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      selectedRowData: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { councilMemberSaveSuccess, fetchTableData, councilMemberUpdateSuccess } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if ((prevProps.councilMemberSaveSuccess !== councilMemberSaveSuccess && councilMemberSaveSuccess)
     || (prevProps.councilMemberUpdateSuccess !== councilMemberUpdateSuccess && councilMemberUpdateSuccess)) {
      this.toggle();
      fetchTableData();
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  setSelectedRow = (rowData) => {
    const { pagePrivileges } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        selectedRowData: rowData,
        prevSelectedRowId: rowData.id,
      });
    }
  };

  onSubmitCouncil = (data) => {
    const { saveCouncilMember, id, updateCouncilMember } = this.props;
    if (data.id) {
      updateCouncilMember(data);
    } else {
      saveCouncilMember(data);
    }
  }

  render() {
    const {
      tableData, councilMemberList, tabs, changeTab, currentTabIndex, fetchTableData, history,
    } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId, selectedRowData,
    } = this.state;

    return (
      <>
        <div className="mb-4">
          <Table
            data={councilMemberList}
            tableTitle="Akreditasyon Kurulu Üyeleri"
            newButtonBool={this.props.newButtonBool}
            newButtonOnClick={this.toggle}
            refreshButtonBool
            refreshButtonAction={() => fetchTableData()}
            columns={[
              { title: 'Unvanı', field: 'title' },
              { title: 'Adı Soyadı', field: 'get_fullName_with_isPresident' },
              { title: 'E-Posta', field: 'email' },
            ]}
            setSelectedRow={this.setSelectedRow}
            selectedRowId={selectedRowId}
            prevSelectedRowId={prevSelectedRowId}
            changeRowsPerPage={this.changeRowsPerPage}
            changePage={this.changePage}
            hasTabs
            tabButtons={tabs}
            changeTab={changeTab}
            currentTab={currentTabIndex}
            maxBodyHeight={false}
          />
          <GenericModal
            isModalOpen={isModalOpen}
            button={<Button className="d-none my-2" style={{ width: 0 }} />}
            toggleModal={this.toggle}
            onClosed={() => this.setSelectedRow(null)}
            content={(
              <div>
                <UserForm id={selectedRowId} isCouncil onSubmit={this.onSubmitCouncil} />
              </div>
              )}
          />
        </div>
      </>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(list('council/member'));
  },
  saveCouncilMember(values) {
    dispatch(save('council/member', values));
  },
  updateCouncilMember(values) {
    dispatch(putAction('council/member/update', values));
  },
});
const mapStateToProps = (state) => ({
  councilMemberList: state.councilMemberList,
  councilMemberSaveSuccess: state.councilMemberSave.success,
  councilMemberUpdateSuccess: state.councilMemberUpdate.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersTableContainer));
