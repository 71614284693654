/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-trailing-spaces */

/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */
/* eslint-disable  no-param-reassign */
/* eslint-disable  vars-on-top */
/* eslint-disable  no-var */
/* eslint-disable  curly */
/* eslint-disable  nonblock-statement-body-position */

import React, { PureComponent, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button } from 'reactstrap';
import { rules } from '../validate';

function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
}

export const RenderFieldInput = (props) => {
  const {
    input, maxLength, min, max, label, value, placeholder, type, mask, isDisabled, lowerCaseBool, pattern, meta: { touched, error },
  } = props;
  // const [selectionStart, setSelectionStart] = useState(0);
  // const inputRef = useRef();
  // const capitalize = (value) => value && value.toLocaleLoverCase('tr-TR');
  // useEffect(() => {
  //   if (!(type === 'email' || type === 'url' || lowerCaseBool) && inputRef.current) {
  //     inputRef.current.setSelectionRange(
  //       selectionStart,
  //       selectionStart,
  //     );
  //   }
  // });
  // const handleChange = (event) => {
  //   const { target } = event;
  //   (async () => {
  //     input.onChange(target.value.toLocaleUpperCase('tr-TR'));
  //     setSelectionStart(inputRef.current.selectionStart);
  //   })(); 
  // };
  return (
    <div className="form__form-group-input-wrap">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {type === 'number' ?
          <input
            {...input}
            className="input_class"
            placeholder={placeholder}
            type={type}
            disabled={isDisabled}
            maxLength={maxLength}
            min={min}
            max={max}
            pattern="[0-9]*"
            onKeyPress={preventNonNumericalInput} 
            onWheel={(e) => e.target.blur()} // disable input scrolling
          />
          :
          type === 'tel' ?
            <input {...input} className="input_class" placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} min={min} max={max} pattern={pattern} onKeyPress={preventNonNumericalInput} />
            :
            <input className="input_class" {...input} onChange={(type === 'email' || type === 'url' || lowerCaseBool) ? input.onChange : input.onChange} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} min={min} max={max} />}
      </div>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

class InputSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    initialValues: PropTypes.any,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submessage: PropTypes.string,
    character: PropTypes.string,
    isDisabled: PropTypes.bool,
    validate: PropTypes.array,
    maxLength: PropTypes.number,
    lowerCaseBool: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    noLabelBool: PropTypes.bool,
    errorMsg: PropTypes.string,
  };

  static defaultProps = {
    initialValues: undefined,
    submessage: undefined,
    isDisabled: false,
    validate: [],
    character: undefined,
    maxLength: undefined,
    lowerCaseBool: false,
    min: undefined,
    max: undefined,
    noLabelBool: false,
    errorMsg: undefined,
  };

  getLayeredObjectValue = (object, target) => {
    const split = target.split('.');
    let returnVal = object;
    for (let i = 0, len = split.length; i < len; i += 1) {
      returnVal = returnVal[split[i]] || false;
    }
    return returnVal || false;
  }

  render() {
    const { t, mode, initialValues, value, parse, maxLength, label, placeholder, name, type, errorMsg, submessage, onChange, character, isDisabled, validate, lowerCaseBool, min, max, style, noLabelBool, pattern } = this.props;
    // const capitalize = (value) => value && value.toLocaleUpperCase('tr-TR');
    return (
      <div className="form__form-group" style={style}>
        {errorMsg && <div className="text-danger mt-3 fs-12">{errorMsg}</div>}
        <span className="form__form-group-label">{label}
        </span>
        <div className="form__form-group-field">
          {mode !== 'view' ?
            <Field
              name={name}
              component={RenderFieldInput}
              type={type}
              placeholder={placeholder}
                // normalize={(type === 'email' || type === 'url' || lowerCaseBool) ? null : capitalize}
              isDisabled={isDisabled}
              lowerCaseBool={lowerCaseBool}
              validate={validate && validate.map(rule => rules[rule])}
              onChange={onChange}
              maxLength={maxLength}
              min={min}
              max={max}
              style={{ width: '100%' }}
              pattern={pattern}
              parse={parse}
              value={value}
            />
            :
            (initialValues && this.getLayeredObjectValue(initialValues, name)
              ? <p style={{ fontStyle: 'italic' }}>{this.getLayeredObjectValue(initialValues, name)}</p>
              : <p>-</p>)}
        </div>
        {mode !== 'view' && character &&
          <span className="form__form-group-character" style={{ fontSize: '11px' }}>
            {character}
          </span>}
      </div>
    );
  }
}

export default InputSection;
