/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable arrow-body-style */
/* eslint-disable react/require-default-props */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import MultiSelect from '@kenshooui/react-multi-select';
import PropTypes from 'prop-types';
import '@kenshooui/react-multi-select/dist/style.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material';
// import LoadingSpinner from '../LoadingSpinner';

export const renderSpinner = () => {
  return (
    <div className="max_width">
      {/* <LoadingSpinner /> */}
      <div>loading...</div>
    </div>
  );
};
class ReactMultiSelectField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func,
    messages: PropTypes.shape({
      searchPlaceholder: PropTypes.string,
      noItemsMessage: PropTypes.string,
      noneSelectedMessage: PropTypes.string,
      selectedMessage: PropTypes.string,
      selectAllMessage: PropTypes.string,
      clearAllMessage: PropTypes.string,
      disabledItemsTooltip: PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      group: PropTypes.string,
      disabled: PropTypes.bool,
    })),
    selectedItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      group: PropTypes.string,
      disabled: PropTypes.bool,
    })),
    showSelectedItems: PropTypes.bool,
    withGrouping: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    selectedItems: [],
    showSelectedItems: false,
    withGrouping: false,
    loading: false,
  };

  // eslint-disable-next-line consistent-return
  handleChange = (values) => {
    const { onChange } = this.props;
    if (values.length === 0) {
      onChange(null);
      return false;
    }
    onChange(values.map((value) => ({ id: value.id })));
    // onChange(values);
  };

  handleClick = (e) => {
  };

  render() {
    const {
      name, items, selectedItems, showSelectedItems, withGrouping, t, messages, loading, value, maxSelectedItems,
    } = this.props;

    const theme = createTheme({
      typography: {
        useNextVariants: true,
        fontSize: 10,
      },
      palette: {
        primary: {
          main: '#70bbfd',
        },
      },
    });
    return (
      <MuiThemeProvider theme={theme}>
      <MultiSelect
        value={value === '' ? [] : value}
        wrapperClassName={name}
        withGrouping={withGrouping}
        items={items}
        messages={messages}
        selectedItems={selectedItems}
        onChange={this.handleChange}
        onClick={this.handleClick}
        showSelectedItems={showSelectedItems}
        loading={loading}
        loaderRenderer={renderSpinner}
        showSelectAll={false}
        maxSelectedItems={maxSelectedItems}
        filterFunction={
          value => item => {
            if (!value || typeof value !== 'string') return false;
            const label = item?.label;
            if (!label || typeof label !== 'string') return false;
            try {
              const valueLower = value.toLocaleLowerCase('tr');
              const labelLower = label.toLocaleLowerCase('tr');
              const valueUpper = value.toUpperCase();
              const labelUpper = label.toUpperCase();
              return labelLower.includes(valueLower) || labelUpper.includes(valueUpper);
            } catch (error) {
              console.warn('MultiSelect filterFunction error:', error);
              return false;
            }
          }
        }
      />
    </MuiThemeProvider>
    
    );
  }
}

const renderReactMultiSelectField = (props) => {
  const {
    input, meta, items, selectedItems, showSelectedItems, withGrouping, editMode, t, loading, validateWithoutTouch, maxSelectedItems,
  } = props;
  const messages = {
    searchPlaceholder: 'Ara...',
    noItemsMessage: 'Seçenek bulunamadı',
    noneSelectedMessage: 'Hiçbir seçim yapılmadı',
    selectedMessage: 'Seçili',
    selectAllMessage: 'Tümünü Seç',
    clearAllMessage: 'Tümünü Temizle',
    disabledItemsTooltip: '',
  };
  return (
    <div className="form__form-group-input-wrap" lang="tr">
      <ReactMultiSelectField
        {...input}
        items={items}
        meta={meta}
        messages={messages}
        loading={loading}
        maxSelectedItems={maxSelectedItems}
        selectedItems={(input && input.value && input.value.length > 0) ?
          (input.value).map((values) => {
            return ({
              id: values.id,
              label: (items && items.find(x => x.id.toString() === ((values.id.toString()))) && items.find(x => x.id.toString() === ((values.id.toString()))).label),
              disabled: items ?
                items.find(x => x.id === ((values.id).toString())) && items.find(x => x.id === ((values.id).toString())).disabled
                :
                false,
            });
          })
          :
          selectedItems}
        showSelectedItems={showSelectedItems}
        withGrouping={withGrouping}
        filterFunction={
          value => item => {
            if (!value || typeof value !== 'string') return false;
            const label = item?.label;
            if (!label || typeof label !== 'string') return false;
            try {
              const valueLower = value.toLocaleLowerCase('tr');
              const labelLower = label.toLocaleLowerCase('tr');
              const valueUpper = value.toUpperCase();
              const labelUpper = label.toUpperCase();
              return labelLower.includes(valueLower) || labelUpper.includes(valueUpper);
            } catch (error) {
              console.warn('MultiSelect filterFunction error:', error);
              return false;
            }
          }
        }
        
      />
      {(meta.touched || validateWithoutTouch) && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderReactMultiSelectField.propTypes = {
  t: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    active: PropTypes.bool,
    error: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    group: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    group: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  showSelectedItems: PropTypes.bool,
  withGrouping: PropTypes.bool,
  editMode: PropTypes.bool,
  loading: PropTypes.bool,
  validateWithoutTouch: PropTypes.bool,
};

renderReactMultiSelectField.defaultProps = {
  meta: null,
  items: [],
  selectedItems: [],
  showSelectedItems: false,
  withGrouping: false,
  editMode: false,
  loading: false,
  validateWithoutTouch: false,
};

export default renderReactMultiSelectField;
