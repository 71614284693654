/* eslint-disable react/static-property-placement */
/* eslint-disable max-len */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */

import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { renderFieldTextArea } from '../../shared/components/generic-form/fieldRenders';
import GenericAccordion from '../../shared/components/GenericAccordion';
import GenericModal from '../../shared/components/GenericModal';
import GenericDescriptionFieldForm from './GenericDescriptionFieldForm';

class AnswerAcceptRejectSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { reviewAddAppealSuccess } = this.props;
    if (prevProps.reviewAddAppealSuccess !== reviewAddAppealSuccess && reviewAddAppealSuccess) {
      this.setState({ isModalOpen: false });
    }
  }

toggleModal = () => {
  const { initialize } = this.props;
  const { isModalOpen } = this.state;
  this.setState({ isModalOpen: !isModalOpen });
}

onSubmit = (values) => {
  const { revisionAnswer, answerId } = this.props;

  revisionAnswer(values, answerId);
}

render() {
  const { label, initialValues,
    customClassName, answer, answerType, docId, docExtension, docName, returnedAnswers, revisionAnswer } = this.props;
  const { isModalOpen } = this.state;
  return (
    <Row className="no-gutters py-3" style={{ borderBottom: '1px solid grey' }}>
      <Col xs={8} className="p-0">
        <span className="form__form-group-label">
          <b> Soru: {label}</b>
          <br />
          {answerType === 'doc' ?
            <span className="ms-2"><b> Belge: </b>
              <Button className="btn-sm ms-2" onClick={() => this.downloadDocument(docId, docExtension, docName)} type="button" color="custom-success">Belgeyi İndir</Button>
            </span> :
            <span className="ms-2"><b> Cevap: </b>{answer}</span>}
        </span>
      </Col>
      <Col xs={4} className="p-0">
        <div className={`d-flex ${customClassName}`} style={{ justifyContent: 'space-around' }}>
          <div id="test">
            {initialValues && initialValues.status === 'REVISION' && <UncontrolledTooltip fade delay={{ show: 100, hide: 100 }} placement="bottom" target="test">Revizyon Beklenirken Yeni Revizyon Ekleyemezsiniz.</UncontrolledTooltip>}
            {initialValues && initialValues.status === 'READY_TO_VISIT' && <UncontrolledTooltip fade delay={{ show: 100, hide: 100 }} placement="bottom" target="test">Bu başvuruyu onayladığınız için artık düzenleme istyemezsiniz.</UncontrolledTooltip>}

            <GenericModal
              isModalOpen={isModalOpen}
              button={(
                <Button color="outline-danger" disabled={initialValues && !(initialValues.status === 'SENT' || initialValues.status === 'REVISION')}>Revizyon İste</Button>
              )}
              toggleModal={this.toggleModal}
              content={
                <GenericDescriptionFieldForm onSubmit={this.onSubmit} />
            }
            />

          </div>

        </div>
      </Col>
      {returnedAnswers &&
      <Col xs={12} className="mt-2 text-danger p-0">
        <div> <i className="fa fa-exclamation-circle" />{returnedAnswers[0].reviewTime} {returnedAnswers[0].description} <br />
        </div>
      </Col>}

    </Row>
  );
}
}

export default (withTranslation('common')(AnswerAcceptRejectSection));
