/* eslint-disable import/prefer-default-export */
const initialState = {
  tabs1: null,
};

export function tabsReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_SELECTED_TAB':
      return {
        ...state,
        [action.namespace]: action.tab,
      };

    default:
      return state;
  }
}
