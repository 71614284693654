/* eslint-disable max-len */
import React from 'react';
import { Chip } from '@material-ui/core';

export const appealStatus = {
  NEW: <Chip className="tableChip bg-custom-primary" size="small" label="Yeni" />,
  SENT: <Chip className="tableChip bg-custom-primary" size="small" label="Gönderildi" />,
  APPEAL_REVISION: <Chip className="tableChip bg-custom-warning" size="small" label="Başvuru Revizyonu" />,
  WAITING_DATE_SELECTION: <Chip className="tableChip bg-custom-primary" size="small" label="Tarih Ataması Bekleniyor" />,
  WAITING_VISIT_PLANNING: <Chip className="tableChip bg-custom-primary" size="small" label="Yerinde Değerlendirme Planlama Sürecinde" />,
  REVISION: <Chip className="tableChip bg-custom-warning" size="small" label="Revizyon" />,
  READY_TO_VISIT: <Chip className="tableChip bg-custom-info" size="small" label="Yerinde Değerlendirme" />,
  REJECTED: <Chip className="tableChip bg-custom-danger" size="small" label="Reddedildi" />,
  COUNCIL_ASSIGNED: <Chip className="tableChip bg-custom-success" size="small" label="Heyet Ataması Yapıldı" />,
  VISIT_REVIEW: <Chip className="tableChip bg-custom-info" size="small" label="Heyet Değerlendirme Sürecinde" />,
  REVISION_REPORT_REQUESTED: <Chip className="tableChip bg-custom-danger" size="small" label="Revizyon Raporu İsteniyor" />,
  REVISIT_REQUESTED: <Chip className="tableChip bg-custom-warning" size="small" label="Tekrar Yerinde Değerlendirme" />,
  REVISION_REPORT_SENT: <Chip className="tableChip bg-custom-success" size="small" label="Revizyon Raporu Gönderildi" />,
  CONFIRMED: <Chip className="tableChip bg-success" size="small" label="Akreditasyon Onaylandı" />,
  NON_SATISFIED: <Chip className="tableChip bg-custom-warning" size="small" label="Değerlendirme Kriterleri Karşılanmadı" />,
  CANCELED: <Chip className="tableChip bg-danger" size="small" label="İptal Edildi" />,
  MEMBER_CONFIRMATION: <Chip className="tableChip bg-custom-info" size="small" label="Akreditasyon Kurul Onayında" />,
  REVISIT_WAITING_DATE_SELECTION: <Chip className="tableChip bg-custom-info" size="small" label="Tarih Seçimi Bekleniyor(Yeniden Değerlendirme)" />,
};
export const appealStatusOptionList = [
  { value: null, label: <Chip className="tableChip bg-primary" size="small" label="Tümü" /> },
  { value: 'NEW', label: <Chip className="tableChip bg-custom-primary" size="small" label="Yeni" /> },
  { value: 'SENT', label: <Chip className="tableChip bg-custom-primary" size="small" label="Gönderildi" /> },
  { value: 'APPEAL_REVISION', label: <Chip className="tableChip bg-custom-warning" size="small" label="Başvuru Revizyonu" /> },
  { value: 'WAITING_DATE_SELECTION', label: <Chip className="tableChip bg-custom-primary" size="small" label="Tarih Ataması Bekleniyor" /> },
  { value: 'WAITING_VISIT_PLANNING', label: <Chip className="tableChip bg-custom-primary" size="small" label="Yerinde Değerlendirme Planlama Sürecinde" /> },
  { value: 'REVISION', label: <Chip className="tableChip bg-custom-warning" size="small" label="Revizyon" /> },
  { value: 'READY_TO_VISIT', label: <Chip className="tableChip bg-custom-info" size="small" label="Yerinde Değerlendirme" /> },
  { value: 'REJECTED', label: <Chip className="tableChip bg-custom-danger" size="small" label="Reddedildi" /> },
  { value: 'COUNCIL_ASSIGNED', label: <Chip className="tableChip bg-custom-success" size="small" label="Heyet Ataması Yapıldı" /> },
  { value: 'VISIT_REVIEW', label: <Chip className="tableChip bg-custom-info" size="small" label="Heyet Değerlendirme Sürecinde" /> },
  { value: 'REVISION_REPORT_REQUESTED', label: <Chip className="tableChip bg-custom-danger" size="small" label="Revizyon Raporu İsteniyor" /> },
  { value: 'REVISIT_REQUESTED', label: <Chip className="tableChip bg-custom-warning" size="small" label="Tekrar Yerinde Değerlendirme" /> },
  { value: 'REVISION_REPORT_SENT', label: <Chip className="tableChip bg-custom-success" size="small" label="Revizyon Raporu Gönderildi" /> },
  { value: 'CONFIRMED', label: <Chip className="tableChip bg-success" size="small" label="Akreditasyon Onaylandı" /> },
  { value: 'NON_SATISFIED', label: <Chip className="tableChip bg-custom-warning" size="small" label="Değerlendirme Kriterleri Karşılanmadı" /> },
  { value: 'CANCELED', label: <Chip className="tableChip bg-danger" size="small" label="İptal Edildi" /> },
  { value: 'MEMBER_CONFIRMATION', label: <Chip className="tableChip bg-custom-info" size="small" label="Akreditasyon Kurul Onayında" /> },
  { value: 'REVISIT_WAITING_DATE_SELECTION', label: <Chip className="tableChip bg-custom-info" size="small" label="Tarih Seçimi Bekleniyor(Yeniden Değerlendirme)" /> },
];
export const visitStatus = {
  ASSIGNED: <Chip className="tableChip bg-custom-primary" size="small" label="Atama Yapıldı" />,
  REVIEW_CREATED: <Chip className="tableChip bg-custom-info" size="small" label="Değerlendirme Sürecinde" />,
  REVIEW_COMPLETED: <Chip className="tableChip bg-custom-success" size="small" label="Değerlendirme Tamamlandı" />,
};

export const hospitalRegisterStatus = {
  NEW: <Chip className="tableChip bg-custom-primary" size="small" label="Yeni" />,
  REVISION: <Chip className="tableChip bg-custom-warning" size="small" label="Revizyon" />,
  CANCELED: <Chip className="tableChip bg-danger" size="small" label="İptal" />,
  REJECTED: <Chip className="tableChip bg-custom-danger" size="small" label="Reddedildi" />,
};
export const hospitalRegisterStatusOptionList = [
  { value: null, label: <Chip className="tableChip bg-primary" size="small" label="Tümü" /> },
  { value: 'NEW', label: <Chip className="bg-custom-primary" size="small" label="Yeni" style={{ height: '18px', paddingTop: '2px' }} /> },
  { value: 'REVISION', label: <Chip className="bg-custom-warning" size="small" label="Revizyon" style={{ height: '18px', paddingTop: '2px' }} /> },
  { value: 'CANCELED', label: <Chip className="bg-danger" size="small" label="İptal" style={{ height: '18px', paddingTop: '2px' }} /> },
  { value: 'REJECTED', label: <Chip className="bg-custom-danger" size="small" label="Reddedildi" style={{ height: '18px', paddingTop: '2px' }} /> },
];
export const memberTypeLookup = {
  NUTRISIONIST: 'Diyetisyen',
  DOCTOR: 'Hekim',
  NURSE: 'Hemşire',
  PHARMACIST: 'Eczacı',
};
export const tableFilterStyles = {
  menu: (styles) => ({
    ...styles,
    borderBottom: '1px solid #E9E9F0',
    width: '100%',
    minWidth: '200px !important',
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '28px',
    height: '28px',
    alignItems: 'flex-start',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    alignItems: 'flex-start',
    minHeight: '28px',
    height: '28px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '4px',
  }),
  input: (styles) => ({
    ...styles,
    minWidth: '200px !important',
    padding: 0,
    fontSize: '12px',
  }),
};
