/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-linebreak */
import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { Role } from './PrivateRoute/role';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import Login from '../Login/index';
import { RedirectRoot } from './PrivateRoute/RedirectRoot';
import HomePage from '../HomePage/index';
import { getUserModules, getUserPrivilages } from './helper/auth-user-info';
import { privilegeSet } from './helper/privileges';
import Dashboard from '../Dashboard';
import ChangePassword from '../ChangePassword';
import NutritionalSupportTeam from '../NutritionalSupportTeam';
import Applications from '../Applications';
import NewApplication from '../NewApplication';
import Users from '../Users';
import Executive from '../ChangePassword/Executive';
import Register from '../Register';
import HospitalTabs from '../Hospitals';
import ApplicationDetailContainer from '../ApplicationDetailContainer';
import VisitDetail from '../VisitDetail';
import HospitalAppeals from '../HospitalAppeals';
import HospitalDashboard from '../HospitalDashboard';
import UsersDashboard from '../UsersDashboard/UsersDashboard';
import Accreditation from '../Accreditation';

const mapRoutes = () => {
  // get priviliages, map it from const object, return an array.
  const privileges = getUserPrivilages() || [];
  
  // Güvenli hale getirelim
  // const queries = Array.isArray(privileges) ? 
  //   privileges
  //     .filter((prv) => typeof prv === 'string' && prv.includes('SCREEN'))
  //     .map((e) => e.replace('_SCREEN', ''))
  //     .sort() 
  //   : [];

  // getUserModules'u da güvenli hale getirelim
  const modules = getUserModules() || [];
  const userRoutes = Array.isArray(modules) ? 
    modules
      .map((e) => privilegeSet[e])
      .filter(el => el !== undefined && el.route !== 'none')
    : [];

  return userRoutes.length > 0 ? userRoutes : [];
};

const wrappedRoutes = () => (
  <div>
    <Layout />
    <Row className="bg-ghostwhite-light">
      <Col xs={12} lg={1} xl={2} className="" />
      <Col xs={12} lg={10} xl={8}>
        <div className="container__wrap">
          {/* {mapRoutes().map(prvRoute => <PrivateRoute exact path={prvRoute.route} roles={[prvRoute.privileges.screen]} component={prvRoute.component} extraProps={{ pagePrivileges: prvRoute.privileges }} />)} */}
          <PrivateRoute
            exact
            path="/"
            roles={[
              Role.ADMIN, Role.HOSPITAL, Role.COUNCIL_MEMBER, Role.EXTERNAL_VISITOR]}
            component={RedirectRoot}
          />
          <PrivateRoute
            exact
            path="/home"
            roles={[
              Role.ADMIN, Role.HOSPITAL, Role.COUNCIL_MEMBER, Role.EXTERNAL_VISITOR]}
            component={RedirectRoot}
          />
          <PrivateRoute
            exact
            path="/admin_dashboard"
            roles={[Role.ADMIN]}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/NST"
            roles={[Role.HOSPITAL]}
            component={NutritionalSupportTeam}
          />
          <PrivateRoute
            path="/applications"
            roles={[Role.ADMIN]}
            component={Applications}
          />
          <PrivateRoute
            path="/accreditation"
            roles={[Role.ADMIN]}
            component={Accreditation}
          />
          <PrivateRoute
            path="/application/details"
            roles={[Role.ADMIN]}
            component={ApplicationDetailContainer}
          />
          <PrivateRoute
            path="/visit/details"
            roles={[Role.ADMIN, Role.COUNCIL_MEMBER, Role.EXTERNAL_VISITOR, Role.HOSPITAL]}
            component={VisitDetail}
          />
          <PrivateRoute
            path="/users"
            roles={[Role.ADMIN]}
            component={Users}
          />
          <PrivateRoute
            path="/hospitals"
            roles={[Role.ADMIN]}
            component={HospitalTabs}
          />
          <PrivateRoute
            path="/hospital_dashboard"
            roles={[Role.HOSPITAL]}
            component={HospitalDashboard}
          />
          <PrivateRoute
            path="/hospital_appeals"
            roles={[Role.HOSPITAL]}
            component={HospitalAppeals}
          />
          <PrivateRoute
            path="/user-dashboard"
            roles={[Role.COUNCIL_MEMBER, Role.EXTERNAL_VISITOR]}
            component={UsersDashboard}
          />
          <PrivateRoute
            path="/new-application"
            roles={[Role.ADMIN, Role.HOSPITAL]}
            component={NewApplication}
          />
          <PrivateRoute
            path="/change_password"
            component={ChangePassword}
          />
          <PrivateRoute
            path="/executive"
            component={Executive}
          />
        </div>
      </Col>
      <Col xs={12} lg={1} xl={2} className="" />
    </Row>

  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/:token" component={Register} />
        <Route exact path="/welcome" component={HomePage} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
