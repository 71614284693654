/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { rules } from '../validate';
import renderSelectField from './Select';

class SelectSection extends PureComponent {
  componentDidMount() {
    const { changeFieldValue, name, defaultValue } = this.props;
    if (defaultValue) {
      changeFieldValue(name, defaultValue);
    }
  }

  getLayeredObjectValue = (object, target, options) => {
    const split = target.split('.');
    let returnVal = object;
    const isId = split[split.length - 1] === 'id';
    for (let i = 0, len = isId ? split.length - 1 : split.length; i < len; i += 1) {
      returnVal = returnVal[split[i]] || false;
    }

    return isId ? (returnVal.name || returnVal.label || returnVal.title || '-') : (options.map((opt) => (opt.value === returnVal ? opt.label : undefined))) || '-';
  }

  render() {
    const {
      label, mode, name, placeholder, options, onChangeGetName,
      validate, initialValues, loading, disabled, defaultValue, onChange, disabledOptionDescription, isRegisterField,
    } = this.props;
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">{label}</span>
        <div className="form__form-group-field">
          {mode !== 'view'
            ? (
              <Field
                name={name}
                component={renderSelectField}
                isEmpty
                placeholder={placeholder}
                onChangeGetName={onChangeGetName}
                options={options}
                validate={validate && validate.map((rule) => rules[rule])}
                loading={loading}
                isDisabled={disabled}
                defaultValue={defaultValue}
                onChange={onChange}
                disabledOptionDescription={disabledOptionDescription}
                isRegisterField={isRegisterField}
              />
            )

            : initialValues !== undefined && initialValues !== null
              ? <p style={{ fontStyle: 'italic' }}>{this.getLayeredObjectValue(initialValues, name, options)}</p>
              : <p>-</p>}
        </div>
      </div>
    );
  }
}

export default SelectSection;
