import jwtDecode from 'jwt-decode';

function token() {
  const tokens = localStorage.getItem('token');
  return tokens;
}

export function getToken() {
  return token();
}

export function getDecodedToken() {
  return jwtDecode(token());
}

export function getTokenExp() {
  return getDecodedToken().exp;
}

export function createToken(res) {
  localStorage.setItem('token', res);
}

export function removeToken() {
  localStorage.removeItem('token');
}
