/* eslint-disable import/prefer-default-export */
/* eslint-disable */
/* eslint-disable no-else-return */
export function authHeader() {
  // return authorization header with jwt token
  const token = localStorage.getItem('token');
  if (token) {
    return {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
        Accept: '*/*',
      },
    };
  } else {
    return {};
  }
}

export function authFileHeader() {
  // return authorization header with jwt token
  const token = localStorage.getItem('token');
  if (token) {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token,
      },
    };
  } else {
    return {};
  }
}
