/* eslint-disable no-plusplus */
/* eslint-disable */

import { removeToken } from './auth-token';

function getUserJSON() {
  try {
    const user = localStorage.getItem('userInfo');
    if (!user) return null;
    
    const parsed = JSON.parse(user);
    return parsed || null;
  } catch (error) {
    console.warn('getUserJSON error:', error);
    return null;
  }
}

export function getUserRoles() {
  const userJSON = getUserJSON();
  return userJSON?.roles || [];
}

export const userRolesControl = (value) => {
  const userRoles = getUserRoles();
  return Array.isArray(userRoles) && typeof value === 'string' ? userRoles.includes(value) : false;
};


export function getUserAccessModules() {
  const userJSON = getUserJSON();
  return userJSON?.accessModules || [];
}

export const userAccessModulesControl = (value) => {
  const modules = getUserAccessModules();
  return Array.isArray(modules) && typeof value === 'string' ? modules.includes(value) : false;
};


export function getPersonnelId() {
  const userJSON = getUserJSON();
  return userJSON?.personnel?.id;
}

export function getUserName() {
  const userJSON = getUserJSON();
  return userJSON?.personnel?.name;
}

export function getUserPrivilages() {
  const userJSON = getUserJSON();
  return Array.isArray(userJSON?.roles) ? userJSON.roles : [];
}

export function getUserModules() {
  const privileges = getUserPrivilages();
  return Array.isArray(privileges) ? 
    privileges
      .filter(prv => typeof prv === 'string' && prv.includes('SCREEN'))
      .map(e => e.replace('_SCREEN', ''))
      .sort()
    : [];
}


export function getUserDepartments() {
  const userJSON = getUserJSON();
  return Array.isArray(userJSON?.departments) ? userJSON.departments : [];
}

export const userDepartmentControl = (departmentId) => {
  const userDepartments = getUserDepartments();
  return departmentId && Array.isArray(userDepartments) ? userDepartments.includes(+departmentId) : false;
};


export function getUserId() {
  const userJSON = getUserJSON();
  return userJSON?.userId;
}

export function getMemberId() {
  const userJSON = getUserJSON();
  return userJSON?.memberId;
}

export function nullCheck(path) {
  const splittedPath = path.split('.');
  let value = '';
  for (let i = 1; i < splittedPath.length + 1; i++) {
    for (let j = 0; j < i; j++) {
      if (j + 1 === i) {
        value += `${splittedPath[j]}`;
      } else {
        value += `${splittedPath[j]}.`;
      }
      if (j + 1 !== i) {
        value += ' && ';
      }
    }
  }
  return value;
}

export function hasUserDashboardAccess() {
  const userJSON = getUserJSON();
  return !!userJSON?.hasDashboardAccess;
}
