import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Col } from 'reactstrap';

const SidebarLink = ({
  // eslint-disable-next-line react/prop-types
  title, icon, route, onClick, style,
}) => (
  <Col className="px-2">
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="bg-ghostwhite"
      className="navLinkResponsive"
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: '100%',
      }}
    >
      <div>
        {icon && <i className={`${icon} NavLinkIconResponsive`} />} <br />
        <span className="d-none d-md-block">
          {title}
        </span>
      </div>

    </NavLink>
  </Col>

);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
