/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import ornekBelge from '../../../doc/ornek-yetki-belgesi.docx';
import { renderFileField } from '../fieldRenders';
import { rules } from './validate';

export default class PdfUploadFormSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      name, label, isRegisterField, onChange, validate, labelStyle,
    } = this.props;
    // const { name, label } = this.props;
    return (
      isRegisterField
        ? (
          <div
            className="form__form-group d-flex align-center"
            style={{
              height: 'auto', padding: '1rem', marginTop: '1rem', backgroundColor: '#f6f6f6', borderRadius: '4px',
            }}
          >
            <div className="me-2" style={labelStyle}>
              {label}
            </div>
            <div>
              <Field
                name={name}
                component={renderFileField}
                isRegisterField={isRegisterField}
                type="file"
                accept="application/pdf"
                onChange={onChange}
                validate={validate && validate.map((rule) => rules[rule])}
              />
            </div>
            <div className="ms-auto">
              <a href={ornekBelge} className="btn-custom-success px-2 py-1 rounded" target="_blank" rel="noopener noreferrer">Örnek Yetkilendirme Belgesi İndir</a>
            </div>
          </div>
        )
        : (
          <div>{label}
            <div>
              <Field
                name={name}
                component={renderFileField}
                type="file"
                accept="application/pdf"
              />

            </div>
          </div>
        )
    );
  }
}
