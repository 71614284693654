/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable camelcase */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React from 'react';
import MaskedInput from 'react-text-mask';

export const renderField = ({
  input, placeholder, type, mask, isDisabled, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <MaskedInput {...input} placeholder={placeholder} type={type} mask={mask} disabled={isDisabled} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

export const renderFileField = ({
  input, name, type, accept, value, meta: { touched, error },
}) => (
  <>
    {touched && error && <span className="form__form-group-error">{error}</span>}
    <input {...input} value={value} type={type} name={name} accept={accept} />
  </>
);

export const renderFieldInput = ({
  input, label, maxLength, placeholder, type, mask, isDisabled, meta: { touched, error } }) => (
    <div className="form__form-group-input-wrap">
      <input {...input} style={{ width: '100%', marginBottom: '0.5rem' }} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} />
    </div>
);

export const renderLoginFieldInput = ({
  input, id, maxLength, placeholder, type, customClassName, isDisabled, meta: { touched, error } }) => (
    <>
      {touched && error && <span className="form__form-group-error">{error}</span>}
      <input {...input} id={id} placeholder={placeholder} className={customClassName} type={type} disabled={isDisabled} maxLength={maxLength} />
    </>
);

export const renderFieldTextArea = ({
  input, maxLength, label, placeholder, type, mask, isDisabled, meta: { touched, error } }) => (
    <div className="w-100">
      <textarea {...input} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} style={{ width: '100%' }} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

export const renderFieldModalTextArea = ({
  input, maxLength, label, placeholder, type, mask, isDisabled, meta: { touched, error } }) => (
    <div className="form__form-group-input-wrap">
      <textarea {...input} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} style={{ textTransform: 'uppercase', height: '100px', width: '100%' }} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

export const renderFieldTextAreaField = ({
  input, maxLength, label, placeholder, type, mask, isDisabled, meta: { touched, error } }) => (
    <div className="form__form-group-input-wrap">
      <textarea {...input} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);
