/* eslint-disable */
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Row } from 'reactstrap';
import tr from 'date-fns/locale/tr'; // Türkçe desteği için
import LocationModal from '../../Layout/topbar/LocationModal'; // Import ekleyelim

const DateRangeSelection = ({ onSubmit, appealId }) => {
  const [dates, setDates] = useState([
    { startDate: null, endDate: null },
    { startDate: null, endDate: null },
    { startDate: null, endDate: null }
  ]);

  // Modal state'lerini ekleyelim
  
  

 

  const handleDateChange = (index, field, date) => {
    const newDates = [...dates];
    newDates[index][field] = date;
    setDates(newDates);
  };

  const handleSubmit = async () => {
    const isValid = dates.every(date => date.startDate && date.endDate);
    
    if (!isValid) {
      alert('Lütfen tüm tarih aralıklarını doldurun');
      return;
    }

    // API'ye gönder
    onSubmit(appealId, {
      availableVisitDateDtoList: dates
    });
  };

  return (
    <div className="date-range-selection p-4">
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <p>Lütfen yerinde değerlendirme için uygun olduğunuz 3 farklı tarih aralığı belirleyin:</p>
        
      </div>

      {dates.map((date, index) => (
        <div key={index} className="mb-4">
          <h6 className="mb-3">Tarih Aralığı {index + 1}</h6>
          <Row>
            <Col xs={6}>
              <div className="form-group">
                <label className="mb-2" style={{ marginRight: '14px' }}>Başlangıç Tarihi:</label>
                <DatePicker
                  selected={date.startDate}
                  onChange={(date) => handleDateChange(index, 'startDate', date)}
                  selectsStart
                  startDate={date.startDate}
                  endDate={date.endDate}
                  locale={tr}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Başlangıç tarihi seçin"
                  className="form-control"
                  minDate={new Date()}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="form-group">
                <label className="mb-2" style={{ marginRight: '14px' }}>Bitiş Tarihi:</label>
                <DatePicker
                  selected={date.endDate}
                  onChange={(date) => handleDateChange(index, 'endDate', date)}
                  selectsEnd
                  startDate={date.startDate}
                  endDate={date.endDate}
                  locale={tr}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Bitiş tarihi seçin"
                  className="form-control"
                  minDate={date.startDate || new Date()}
                />
              </div>
            </Col>
          </Row>
        </div>
      ))}

      <div className="text-center mt-4">
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!dates.every(date => date.startDate && date.endDate)}
        >
          Tarihleri Kaydet
        </Button>
      </div>

      {/* LocationModal'ı en sonda render edelim */}
      
    </div>
  );
};

export default DateRangeSelection;

