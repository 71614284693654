/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const RedirectRoot = ({ component: Component, roles, extraProps, ...rest }) => (
  <Route {...rest} render={props => {
    const currentUser = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : localStorage.getItem('userInfo');
    if (currentUser && currentUser.type === 'ADMIN') {
      return <Redirect to={{ pathname: '/hospitals', state: { from: props.location } }} />;
    }
    if (currentUser && currentUser.type === 'HOSPITAL') {
      return <Redirect to={{ pathname: '/hospital_dashboard', state: { from: props.location } }} />;
    }
    if (currentUser && currentUser.type === 'COUNCIL_MEMBER') {
      return <Redirect to={{ pathname: '/user-dashboard', state: { from: props.location } }} />;
    }
    if (currentUser && currentUser.type === 'EXTERNAL_VISITOR') {
      return <Redirect to={{ pathname: '/user-dashboard', state: { from: props.location } }} />;
    }
    if (!currentUser) {
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }
    return ''; // test için. sonra routeları değiştir
  }} />
);
