/* eslint-disable */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getVisit, putVisit } from '../../redux/actions/visitAction';
import { getMemberId, userRolesControl } from '../App/helper/auth-user-info';
import { appealStatus } from '../App/helper/statusTableLookupValues';
import AddReportForm from '../Applications/compontents/AddReportForm';
import { download } from '../../redux/actions/downloadAction';
import GenericModal from '../../shared/components/GenericModal';
import ScoreStandardsDetail from './ScoreStandardsDetail';
import LocationModal from '../Layout/topbar/LocationModal';

class CreateVisitReview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isScoreDetailModalOpen: false,
      isLocationModalOpen: false,
    };
  }

  componentDidMount() {
    const { id, getVisitDetail, calculate } = this.props;
    if (id) {
      getVisitDetail(id);
    }
    calculate('reset');
  }

  componentDidUpdate(prevProps) {
    const {
      id, getVisitDetail, calculate, reviewCreateVisitSuccess, reviewCreateVisit, history, visitReviewSetRevisitSuccess,
    } = this.props;
    if (prevProps.reviewCreateVisitSuccess !== reviewCreateVisitSuccess && reviewCreateVisitSuccess) {
      history.push({
        pathname: 'visit/details',
        state: { visitId: reviewCreateVisit },
      });
    }
    if (visitReviewSetRevisitSuccess !== prevProps.visitReviewSetRevisitSuccess && visitReviewSetRevisitSuccess) {
      if (id) {
        getVisitDetail(id);
      }
      calculate('reset');
    }
  }

  updateVisit = (id) => {
    const { mode, initialValues, history } = this.props;
    const visitMode = initialValues
    && ((initialValues.appealDto && initialValues.appealDto.status === 'REVISION_REPORT_REQUESTED')
    || initialValues.status === 'REVISIT'
    || initialValues.status === 'REVIEW_COMPLETED') ? 'view' : mode;

    history.push({
      pathname: 'visit/details',
      state: { visitId: id, mode: visitMode },
    });
  }

  setVisitStatus = (id) => {
    const { setVisitStatusToRevisit } = this.props;
    setVisitStatusToRevisit(id);
  }

  updateVisitByVisit = (id) => {
    const { mode, history } = this.props;

    history.push({
      pathname: 'visit/details',
      state: { realVisitId: id, mode },
    });
  }

  visitReviewCreate = (id) => {
    const { getVisitReviewCreate } = this.props;
    getVisitReviewCreate(id);
  }

  visitReviewPublish = (id) => {
    const { reviewPublish } = this.props;
    reviewPublish(id);
  }

  visitReviewCalculate = (id) => {
    const { calculate } = this.props;
    calculate(id);
  }

  downloadDocument = (id) => {
    const { download } = this.props;
    download(id);
  }

  toggleScoreDetailModal = () => {
    this.setState((prevState) => ({ isScoreDetailModalOpen: !prevState.isScoreDetailModalOpen }));
  }

  toggleLocationModal = () => {
    this.setState(prevState => ({
      isLocationModalOpen: !prevState.isLocationModalOpen
    }));
  };

  openLocationModal = (status, visitStatus) => {
    console.log('status', status);
    console.log('visitStatus', visitStatus);
    this.setState({
      isLocationModalOpen: true
    });
  };

  render() {
    const { initialValues, reviewCalculateVisit } = this.props;
    const { isScoreDetailModalOpen, isLocationModalOpen } = this.state;
    return (
      <Row>
        {initialValues && (
          <Card>
            <CardBody className="p-0 p-md-2">
              <Row>
                <Col xs={12}>
                  <div className="d-flex justify-content-between">
                    <h5>{initialValues?.appealDto?.hospitalDto?.title} </h5>
                    <span 
                      style={{ fontSize: '14px', cursor: 'pointer',border: '1px solid #b81414', padding: '5px', borderRadius: '5px',color: '#b81414' }}
                      onClick={() => this.openLocationModal(initialValues?.appealDto?.status, initialValues?.status)}
                    >
                      Akreditasyon Süreci <i className="lnr lnr-question-circle" />
                    </span>
                  </div>
                  <i>
                    {`${initialValues?.appealDto?.hospitalDto?.countyDto?.cityDto?.name}
                    ${` ${initialValues?.appealDto?.hospitalDto?.countyDto?.name}`}`}
                  </i>
                  {initialValues.visitDate
                  && <b style={{ float: 'right' }}> Yerinde Görüşme Tarihi {`${initialValues.visitDate}`}</b>}
                  <hr />
                </Col>
                <Col xs={12} className="mb-3">
                  {initialValues.visitMemberDtoList && initialValues.visitMemberDtoList.length > 0
                    ? <h6>Yerinde Görüşme Ekibi</h6>
                    : <h6>Henüz Ekip Ataması Yapılmamış</h6>}
                  <ul>
                    {initialValues.visitMemberDtoList && initialValues.visitMemberDtoList.length > 0
                     && initialValues.visitMemberDtoList.map((member) => (
                       <li>{member.councilMemberDto
                         ? (`${member.councilMemberDto.title} ${member.councilMemberDto.name} ${member.councilMemberDto.surname}  ${member.headOfCommittee === true ? '(Başkan)' : ''}`) : (`${member.externalVisitorDto.title} ${member.externalVisitorDto.name} ${member.externalVisitorDto.surname}  ${member.headOfCommittee === true ? '(Başkan)' : ''}`) }
                       </li>
                     ))}
                  </ul>
                </Col>
                {initialValues && initialValues.appealDto
                && (
                <Col xs={12} className="mb-3">
                  {
                  (initialValues.appealDto.status === 'VISIT_REVIEW'
                || initialValues.appealDto.status === 'REVISION_REPORT_SENT'
                || initialValues.appealDto.status === 'REVISIT_REQUESTED'
                || !(initialValues.status === 'ASSIGNED' && (userRolesControl('HOSPITAL') || userRolesControl('ADMIN'))))
                && initialValues.status !== 'REVIEW_COMPLETED'
                    && (
                    <div>
                      <div>
                        <>
                          {!userRolesControl('HOSPITAL') && !userRolesControl('ADMIN') && initialValues.status !== 'REVIEW_COMPLETED' && initialValues.status !== 'REVISIT'
                        && <Button className="btn-custom-primary" size="sm" onClick={() => this.updateVisit(initialValues.reviewDto.id)}>Değerlendir</Button>}
                        </>
                        <>
                          {
                            initialValues.appealDto.status === 'REVISION_REPORT_SENT'
                            && (
                            <> <Button className="btn-custom-secondary mx-1" size="sm" onClick={() => this.downloadDocument(initialValues.reviewDto.id)}>Revizyon Belgesini Görüntüle</Button>
                              <Button className="btn-custom-warning" size="sm" onClick={() => this.setVisitStatus(initialValues.reviewDto.id)}>Yeniden Yerinde Değerlendirme İste</Button>
                            </>
                            )
}
                        </>
                        {!userRolesControl('HOSPITAL') && initialValues.status !== 'REVISIT' && (
                        <>
                          {((initialValues.visitMemberDtoList && initialValues.visitMemberDtoList.length > 0 && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).councilMemberDto && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).councilMemberDto.id === getMemberId()) || (initialValues.visitMemberDtoList && initialValues.visitMemberDtoList.length > 0 && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).externalVisitorDto && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).externalVisitorDto.id === getMemberId())) ? <Button className="btn-custom-info ms-2" size="sm" onClick={() => this.visitReviewCalculate(initialValues.reviewDto && initialValues.reviewDto.id)}>Sonuçları Hesapla</Button> : null}
                          {((initialValues?.visitMemberDtoList.length > 0 && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).councilMemberDto && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).councilMemberDto.id === getMemberId()) || (initialValues?.visitMemberDtoList.length > 0 && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).externalVisitorDto && initialValues.visitMemberDtoList.find((member) => (member.headOfCommittee)).externalVisitorDto.id === getMemberId())) ? <Button className="btn-custom-success ms-2" size="sm" onClick={() => this.visitReviewPublish(initialValues.reviewDto && initialValues.reviewDto.id)}>Sonuçları Gönder</Button> : null}
                        </>
                        )}

                      </div>
                      {reviewCalculateVisit && reviewCalculateVisit.status && (
                      <div>
                        <div className="mt-3">
                          {reviewCalculateVisit.maxScore} puan üzerinden {reviewCalculateVisit.reviewScore} alarak durumu {appealStatus[reviewCalculateVisit.status]} olarak hesaplanmıştır
                        </div>
                        <div>
                          <GenericModal
                            isModalOpen={isScoreDetailModalOpen}
                            button={(
                              <Button color="outline-primary">Sonuçlar</Button>
                        )}
                            toggleModal={this.toggleScoreDetailModal}
                            content={<ScoreStandardsDetail initialValues={reviewCalculateVisit && reviewCalculateVisit} />}
                          />
                        </div>
                      </div>
                      )}
                    </div>
                    )
}
                  {(initialValues.appealDto.status === 'MEMBER_CONFIRMATION'
                  || initialValues.appealDto.status === 'CONFIRMED'
                  || initialValues.appealDto.status === 'NON_SATISFIED')
                  && (
                  <>
                    {!userRolesControl('HOSPITAL') && !userRolesControl('ADMIN') && initialValues.status !== 'REVIEW_COMPLETED'
                      && <Button className="btn-custom-primary" size="sm" onClick={() => this.updateVisit(initialValues.reviewDto.id)}>Değerlendir</Button>}
                  </>
                  )}
                  {initialValues.status === 'NEW'
                    && (
                    <div>
                      {userRolesControl('ADMIN')
                        ? (
                          <div>
                            Başvurular sekmesinden ilgili başvuruya tıklayarak yeniden ekip ve görüşme tarihi ataması yapmanız gerekmektedir.
                          </div>
                        )
                        : (
                          <div>
                            Heyet ve tarih ataması bekleniyor
                          </div>
                        )}
                    </div>
                    )}
                  {initialValues.status === 'REVIEW_COMPLETED'
                    && (
                    <div>
                      <Button className="btn-custom-primary" size="sm" onClick={() => this.updateVisit(initialValues.reviewDto.id)}>Değerlendirmeleri Görüntüle</Button>
                      {((((initialValues.appealDto.status === 'CONFIRMED' || initialValues.appealDto.status === 'REJECTED' || initialValues.appealDto.status === 'REVISION_REPORT_REQUESTED') && userRolesControl('HOSPITAL')) || !userRolesControl('HOSPITAL')))
                      && (
                      <>  <Button className="btn-custom-info ms-2" size="sm" onClick={() => this.visitReviewCalculate(initialValues.reviewDto && initialValues.reviewDto.id)}>Puanlandırmayı Gör</Button>
                        {reviewCalculateVisit && reviewCalculateVisit.status && (
                        <div>
                          <div className="mt-3">
                            {reviewCalculateVisit.maxScore} puan üzerinden {reviewCalculateVisit.reviewScore} alarak durumu {appealStatus[reviewCalculateVisit.status]} olarak hesaplanmıştır
                          </div>
                          <div>
                            <GenericModal
                              isModalOpen={isScoreDetailModalOpen}
                              button={(
                                <Button color="outline-primary">Sonuçlar</Button>
                          )}
                              toggleModal={this.toggleScoreDetailModal}
                              content={<ScoreStandardsDetail initialValues={reviewCalculateVisit && reviewCalculateVisit} />}
                            />
                          </div>
                        </div>
                        )}
                      </>
                      )}
                    </div>
                    )}
                    {initialValues.status === 'ASSIGNED' && (userRolesControl('HOSPITAL') || userRolesControl('ADMIN'))
                      && (
                      <div>
                        Heyet ataması yapıldı. Heyet değerlendirmesini bitirdiğinde sonuçları görüntüleyebileceksiniz.
                      </div>
                      )}
                  {initialValues.appealDto.status === 'REVISION_REPORT_REQUESTED'
                    && (
                    <div>
                      <div>
                        <div className="mb-3">
                          Yerinde değerlendirme tamamlandı. Hastanenin revizyon raporu göndermesi bekleniyor.
                        </div>
                      </div>
                      {userRolesControl('HOSPITAL')
                      && (
                      <div>
                        <div>
                          <AddReportForm id={initialValues && initialValues.reviewDto && initialValues.reviewDto.id} />
                        </div>
                      </div>
                      )}
                    </div>
                    )}
                    {initialValues.status === 'REVISIT'
                            && <Button className="btn-custom-primary" size="sm" onClick={() => this.updateVisit(initialValues.reviewDto.id)}>Değerlendirmeleri Görüntüle</Button>}
                </Col>
                )}

              </Row>

            </CardBody>
            <LocationModal 
              isOpen={isLocationModalOpen}
              toggle={this.toggleLocationModal}
              status={initialValues?.appealDto?.status}
              visitStatus={initialValues?.status}
              step={2}
            />
          </Card>
        )}
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVisitDetail(id) {
    dispatch(getVisit('id', id));
  },
  getVisitReviewCreate(id) {
    dispatch(putVisit('review/create', {}, id));
  },
  reviewPublish(id) {
    dispatch(putVisit('review/publish', {}, id));
  },
  calculate(id) {
    dispatch(getVisit('review/calculate', id));
  },
  download(id) {
    dispatch(download('visit/review', id, true));
  },
  setVisitStatusToRevisit(id) {
    dispatch(putVisit('review/set/revisit', {}, id));
  },
});

const mapStateToProps = (state) => ({
  initialValues: state.idVisit.data,
  reviewCreateVisitSuccess: state.reviewCreateVisit.success,
  reviewCreateVisit: state.reviewCreateVisit.data,
  reviewCalculateVisit: state.reviewCalculateVisit.data,
  visitReviewSetRevisitSuccess: state.visitReviewSetRevisit.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateVisitReview));
