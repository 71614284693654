/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export  */
/* eslint-disable arrow-parens  */
/* eslint-disable vars-on-top  */
/* eslint-disable  no-var  */
/* eslint-disable  react/react-in-jsx-scope  */
/* eslint-disable  react/jsx-filename-extension  */
/* eslint-disable  consistent-return  */
/* eslint-disable no-throw-literal */
import { getToken, getTokenExp } from '../helper/auth-token';
import { refreshToken, logoutMiddleware } from '../../../redux/actions/authAction';

// new Date((tokenExp.getTime() - 10000000))

export function jwtRefreshMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    if (typeof action === 'function') {
      if (getToken() && !getState().auth.pendingRefreshingToken) {
        const tokenExp = new Date(getTokenExp() * 1000);
        if (new Date() < tokenExp) {
          // const refreshThreshold = new Date((new Date().getTime() + 300000));
          // if (refreshThreshold > new Date(getTokenExp() * 1000)) {
          //   refreshToken(dispatch).then(() => next(action));
          // }
        } else {
          logoutMiddleware(dispatch);
        }
      }
    }
    return next(action);
  };
}
