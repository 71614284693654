/* eslint-disable react/no-did-update-set-state */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Card, CardBody, Col,
} from 'reactstrap';
import Select from 'react-select';
import { Chip } from '@material-ui/core';
import Table from '../../../shared/components/generic-table/Table';
import GenericModal from '../../../shared/components/GenericModal';
import { fetchTableData } from '../../../redux/actions/listTableAction';
import HospitalDetail from '../Compnents/HospitalDetail';
import { confirm } from '../../../redux/actions/confirmAction';
import { reject } from '../../../redux/actions/rejectAction';
import { revision } from '../../../redux/actions/revisionAction';
import HospitalRegisterDetail from '../Compnents/HospitalRegisterDetail';
import { hospitalRegisterStatus, hospitalRegisterStatusOptionList, tableFilterStyles } from '../../App/helper/statusTableLookupValues';

class NewHospitalsTableContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      selectedRowData: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      hospitalRegisterRevisionSuccess, hospitalRegisterRejectSuccess, hospitalRegisterConfirm, fetchTableData,
    } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if (
      (prevProps.hospitalRegisterRevisionSuccess !== hospitalRegisterRevisionSuccess && hospitalRegisterRevisionSuccess)
      || (prevProps.hospitalRegisterRejectSuccess !== hospitalRegisterRejectSuccess && hospitalRegisterRejectSuccess)
      || (prevProps.hospitalRegisterConfirm !== hospitalRegisterConfirm && hospitalRegisterConfirm)) {
      this.setState(() => ({ isModalOpen: false }));
      fetchTableData();
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  changePage = (page) => {
    const { setTablePage, fetchTableData } = this.props;
    setTablePage(page);
    fetchTableData();
  }

  changeRowsPerPage = (itemsPerPagina) => {
    const { setTablePageSize, fetchTableData } = this.props;
    setTablePageSize(itemsPerPagina);
    fetchTableData();
  }

  setSelectedRow = (rowData) => {
    const { pagePrivileges } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        selectedRowData: rowData,
        prevSelectedRowId: rowData.id,
      });
    }
  };

  confirmRegister = (id) => {
    const { confirmRegister } = this.props;
    confirmRegister(id);
  }

  revisionRegister = (id, values) => {
    const { revisionRegister } = this.props;
    revisionRegister(id, values);
  }

  rejectRegister = (id, values) => {
    const { rejectRegister } = this.props;
    rejectRegister(id, values);
  }

  render() {
    const {
      tableData, newHospitals, tabs, changeTab, currentTabIndex, fetchTableData, history,
      hospitalRegisterRevisionSuccess, hospitalRegisterRejectSuccess,
    } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId, selectedRowData,
    } = this.state;
    const rejectedHospitals = {
      ...newHospitals, // newHospitals nesnesinin diğer verilerini koruyoruz
      tableData: newHospitals.tableData?.filter(el => el.status !== 'REJECTED') || [], // Sadece REJECTED olanları tableData'ya atıyoruz
    };
    return (
      <>
        <div className="mb-4">
          <Table
            data={rejectedHospitals}
            tableTitle="Yeni Hastane Kayıtları"
            newButtonBool={false}
            refreshButtonBool
            refreshButtonAction={() => fetchTableData()}
            columns={[
              { title: 'Kayıt Tarihi', field: 'registerTime' },
              { title: 'Hastane Adı', field: 'title' },
              { title: 'Yetkili Adı', field: 'authorityName' },
              { title: 'Yetkili Soyadı', field: 'authoritySurname' },
              { title: 'Yetkili Unvanı', field: 'authorityDuty' },
              { title: 'Yetkili E-Posta', field: 'authorityEmail' },
              { title: 'İl', field: 'countyDto.cityDto.name' },
              { title: 'İlçe', field: 'countyDto.name' },
              // { title: 'Durum', field: 'status', lookup: hospitalRegisterStatus },
              {
                title: 'Durum',
                field: 'status',
                lookup: hospitalRegisterStatus,
                filterComponent: ({ columnDef, onFilterChanged }) => (
                  <Select
                    options={Object.entries({ '': <Chip className="tableChip bg-primary" size="small" label="Tümü" />, ...hospitalRegisterStatus }).map((el) => ({ value: el[0], label: el[1] }))}
                    placeholder="Durum"
                    styles={tableFilterStyles}
                    onChange={(e) => {
                      // Calling the onFilterChanged with the current tableId and the new value
                      onFilterChanged(columnDef.tableData.id, e.value);
                    }}
                  />
                ),
              },

            ]}
            setSelectedRow={this.setSelectedRow}
            selectedRowId={selectedRowId}
            prevSelectedRowId={prevSelectedRowId}
            changeRowsPerPage={this.changeRowsPerPage}
            changePage={this.changePage}
            hasTabs
            tabButtons={tabs}
            changeTab={changeTab}
            currentTab={currentTabIndex}
            maxBodyHeight={false}
          />
          <GenericModal
            isModalOpen={isModalOpen}
            button={<Button className="d-none my-2" style={{ width: 0 }} />}
            toggleModal={this.toggle}
            onClosed={() => this.setSelectedRow(null)}
            content={(
              <div>
                <HospitalRegisterDetail id={selectedRowId && selectedRowId} rejectRegister={this.rejectRegister} confirmRegister={this.confirmRegister} revisionRegister={this.revisionRegister} />
              </div>
              )}
          />
        </div>
      </>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTableData() {
      dispatch(fetchTableData('hospital/register'));
    },
    confirmRegister(id) {
      dispatch(confirm('hospital/register', id));
    },
    rejectRegister(id, values) {
      dispatch(reject('hospital/register', id, values));
    },
    revisionRegister(id, values) {
      dispatch(revision('hospital/register', id, values));
    },
  };
};

const mapStateToProps = (state) => ({
  newHospitals: state.newHospitals,
  hospitalRegisterRevisionSuccess: state.hospitalRegisterRevision.success,
  hospitalRegisterRejectSuccess: state.hospitalRegisterReject.success,
  hospitalRegisterConfirm: state.hospitalRegisterConfirm.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewHospitalsTableContainer));
