/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import HospitalTabs from './Containers/HospitalTabs';

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  render() {
    // const { isModalOpen } = this.state;
    return (
      <Card>
        <CardBody>
          <HospitalTabs />
        </CardBody>
      </Card>
    );
  }
}

export default Users;
