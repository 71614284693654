/* eslint-disable react/no-did-update-set-state */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
  Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@material-ui/core';
import * as React from 'react';

// eslint-disable-next-line react/prefer-stateless-function
class GenericAccordion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      activeAccordionId: null,
    };
  }

  componentDidMount() {
    const { expandAccordion, list } = this.props;
    if (expandAccordion === true) {
      this.setState(() => ({ isExpanded: true }));
      this.setState({ activeAccordionId: list[0].id });
    }
  }

  componentDidUpdate(prevProps) {
    const { expandAccordion, list } = this.props;
    if (prevProps.expandAccordion !== expandAccordion) {
      this.setState(() => ({ isExpanded: expandAccordion }));
      this.setState({ activeAccordionId: list[0].id });
    }
  }

handleChange = (id) => {
  this.setState({ activeAccordionId: id });
  this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
}

render() {
  const {
    list, style, titleStyle, icon, iconColor, backgroundColor, fontSize, customTitleClassName, disabled, hideExpandIcon,
  } = this.props;
  const { isExpanded, activeAccordionId } = this.state;
  return (
    <>
      {list.map((item, index) => (
        <Accordion expanded={(isExpanded && (activeAccordionId === item.id))} onChange={() => { this.handleChange(); }} className="m-0" style={{ style, backgroundColor: `${backgroundColor || 'white'}` }} disabled={disabled}>
          <AccordionSummary
            expandIcon={!disabled && !hideExpandIcon && <i className={`fa fa-${icon || 'chevron-down'}`} style={{ fontSize: `${fontSize || '18px'}`, color: `${iconColor || 'black'}` }} />}
            aria-controls={index}
            id={index}
            style={{ minHeight: '20px' }}
          >
            <Typography className={customTitleClassName} style={titleStyle}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0 16px' }}>
            <Typography className="ml-2 w-100" style={{ cursor: 'pointer' }} onClick={() => this.handleChange()}>
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>

      ))}
    </>

  );
}
}
export default (GenericAccordion);
