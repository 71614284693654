/* eslint-disable no-unused-vars */
/* eslint-disable */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button, Card, CardBody, Row, Col,
} from 'reactstrap';
import OnSiteMeetingDateAndTeamSelectForm from '../compontents/OnSiteMeetingDateAndTeamSelectForm';
import GenericModal from '../../../shared/components/GenericModal';
import RejectAppealForm from '../compontents/RejectAppealForm';
import { appealStatus } from '../../App/helper/statusTableLookupValues';
import { userRolesControl } from '../../App/helper/auth-user-info';
import AccreditationConfirmForm from '../../UsersDashboard/AccreditationConfirmForm';
import GenericAccordion from '../../../shared/components/GenericAccordion';
import RevisionNutritionMemberForm from '../compontents/RevisionNutritionMemberForm';
import ExtendDateForm from '../compontents/ExtendDateForm';
import axios from 'axios';
import NewMeetingDateandTeam from '../compontents/NewMeetingDateandTeam';
import { addNotification } from '../../../redux/actions/notificationActions';
import { useDispatch } from 'react-redux';
import LocationModal from '../../Layout/topbar/LocationModal';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WaitingApplicationTabsContainer(props) {
  const [value, setValue] = React.useState(0);
  const [isGeneralRevisionModalOpen, setisGeneralRevisionModalOpen] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState({
    visitDate: null,
    visitMemberDtoList: [],
    externalVisitorDto: []
  });
  const toggleisGeneralRevisionModalOpen = () => setisGeneralRevisionModalOpen(!isGeneralRevisionModalOpen);
  const dispatch = useDispatch();
  const [isLocationModalOpen, setIsLocationModalOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState(null);

  React.useEffect(() => {
    if (props.initialValues.status === 'READY_TO_VISIT'
     || props.initialValues.status === 'APPEAL_REVISION'
     || props.initialValues.status === 'REJECTED'
     || props.initialValues.status === 'REVISIT_REQUESTED'
     || props.initialValues.status === 'MEMBER_CONFIRMATION'
     || props.initialValues.status === 'REVISIT_WAITING_DATE_SELECTION'
     || props.initialValues.status === 'VISIT_REVIEW') {
      setValue(props.initialValues.appealFormDtoList.length + 1);
    } else {
      setValue(0);
    }
  }, [props.initialValues.status]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const sortData = (data) => data.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
  const rejectAppeal = (values) => {
    props.onSubmitRejectAppeal(values, props.initialValues.id);
  };
  const revisionNutritionMembers = (values) => {
    props.onSubmitRevisionNutrition(values, props.initialValues.id);
  };
  const {
    component: ChildComponent, title, initialValues, id, revisionAnswer, publishReview, reviewAddAppealSuccess,
    confirmAccredidation, showAccForm, toggleAccForm, toggleExtendDate, isExtendDateModalOpen,
    extendDateSubmit, downloadDocument, appealDocumentAttachmentList, downloadAttachmentDocument,
  } = props;
  console.log('initialValues', initialValues.availableVisitDateDtoList);

  const handleTeamSelect = (values) => {
    console.log('Seçilen ekip ve tarih:', values);

    // values zaten doğru formatta geliyor, dönüştürmeye gerek yok
    setSelectedTeam({
      visitDate: values.visitDate,
      visitMemberDtoList: values.visitMemberDtoList
    });
  };

  const confirmAppeal = async (appealId) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!selectedTeam.visitDate || selectedTeam.visitMemberDtoList.length === 0) {
        alert('Lütfen tarih ve ekip üyelerini seçin');
        return;
      }
      console.log('selectedTeam', selectedTeam);
      console.log('Gönderilecek data:', {
        visitDate: selectedTeam.visitDate,
        visitMemberDtoList: selectedTeam.visitMemberDtoList
      });
      
      await axios.post(`${process.env.REACT_APP_API_URL}appeal/confirm/visit/${appealId}`, {
        visitDate: selectedTeam.visitDate,
        visitMemberDtoList: selectedTeam.visitMemberDtoList
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      dispatch(addNotification('Başvuru onaylandı', 'success'));
    } catch (error) {
      console.error('Onaylama hatası:', error);
      dispatch(addNotification('Başvuru onaylanırken bir hata oluştu', 'error'));
    }
  };

  const toggleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen);
  };

  const toggleModalTest = (status) => {
    console.log('status:', status);
    setSelectedStatus(status);
    setIsLocationModalOpen(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="label">
          {initialValues && initialValues.appealFormDtoList && initialValues.appealFormDtoList.length > 0
           && sortData(initialValues.appealFormDtoList).map((tab, index) => (
             <Tab label={tab.title.slice(0, 8)} className="px-2" {...a11yProps(index)} />
           ))}
          <Tab
            label="Üyeler"
            className="px-2"
            {...a11yProps(initialValues
              && initialValues.appealFormDtoList && initialValues.appealFormDtoList.length)}
          />
          <Tab
            label="Başvuru Durumu"
            className="px-2"
            {...a11yProps(initialValues
              && initialValues.appealFormDtoList && initialValues.appealFormDtoList.length + 1)}
          />
        </Tabs>
      </Box>
      {initialValues && initialValues.appealFormDtoList && initialValues.appealFormDtoList.length > 0
      && initialValues.appealFormDtoList.map((tab, index) => (
        <TabPanel value={value} index={index}>
          <h5 className="mb-4">{initialValues.appealFormDtoList[index].title}</h5>
          <ChildComponent
            data={sortData(tab.questionDtoList)}
            title={title}
            id={id}
            revisionAnswer={revisionAnswer}
            reviewAddAppealSuccess={reviewAddAppealSuccess}
            initialValues={initialValues}
          />
        </TabPanel>
      ))}
      <TabPanel value={value} index={initialValues && initialValues.appealFormDtoList.length}>
        <h5 className="mb-4">Nütrisyon Destek Ekibi</h5>
        <Card>
          <CardBody className="p-0 p-md-2">
            <h6>Hastanenin Nütrisyon ekibindeki üyeler aşağıda listelenmiştir.</h6>

            {initialValues.nutritionMemberDtoList && initialValues.nutritionMemberDtoList.length > 0 && initialValues.nutritionMemberDtoList.map((member) => (
              <GenericAccordion
                list={[{
                  title: `${member.title} ${member.name} ${member.surname}`,
                  content: (
                    <div>
                      <Row>
                        <Col xs={6} className="mb-3">
                          E-posta: {member.email}
                        </Col>
                        <Col xs={6} className="mb-3">
                          Görev Başlangıç Tarihi: {member.startDate}
                        </Col>
                        <Col xs={12} className="mb-3">
                          Mezun Olduğu Üniversite: {member.graduatedUniversity}
                        </Col>
                        <Col xs={12} className="mb-3">
                          <h6>Kariyer Geçmişi: </h6>
                          {member.careerHistory}
                        </Col>
                        <Col xs={12} className="mb-3">
                          <h6>Katıldığı Toplantılar: </h6>
                          {member.educationMeeting}
                        </Col>
                      </Row>
                    </div>),
                }]}
              />
            ))}
            {initialValues && initialValues.nutritionMemberRevisionMessage && (
            <Col xs={12} className="mt-2 text-danger p-0">
              Revizyon Mesajı: {initialValues.nutritionMemberRevisionMessage}
            </Col>
            )}
            <GenericModal
              isModalOpen={isGeneralRevisionModalOpen}
              button={<Button color="outline-danger" size="sm" className="mt-2" disabled={initialValues && !(initialValues.status === 'SENT' || initialValues.status === 'APPEAL_REVISION')}> Revizyon İste</Button>}
              toggleModal={toggleisGeneralRevisionModalOpen}
              content={(
                <div>
                  <RevisionNutritionMemberForm onSubmit={revisionNutritionMembers} cancelButtonOnclick={toggleisGeneralRevisionModalOpen} />
                </div>
              )}
            />
          </CardBody>
        </Card>
      </TabPanel>

      <TabPanel value={value} index={initialValues && initialValues.appealFormDtoList.length + 1}>
        <Card className="mb-2">
          <CardBody>
          <div className="d-flex justify-content-between mb-3">
            <h5 className="mb-2">Başvuru Durumu:
              {appealStatus[initialValues.status]}
            </h5>
            <span style={{ fontSize: '14px', cursor: 'pointer' ,border: '1px solid #b81414', padding: '5px', borderRadius: '5px' ,color: '#b81414'}} onClick={() => toggleModalTest(initialValues.status)}>Akreditasyon Süreci <i className="lnr lnr-question-circle" aria-hidden="true"></i></span>
            </div>
            {initialValues.status === 'REJECTED' && (
              <div>
                Reddetme Gerekçesi: {initialValues.rejectMessage}
              </div>
            )}
            {initialValues.status === 'CONFIRMED' && (
            <div>
              <p>
                Karar Metni: {initialValues.decisionDescription}
              </p>
              <p>Karar Tarihi: {initialValues.confirmDate}</p>
            </div>
            )}
            <hr />

            <div className="my-4">
              <h5>Başvuru Dilekçesi</h5>
              {initialValues.hasAppointmentFile ? (
                <div className="my-3">
                  <Button onClick={() => downloadDocument(initialValues.id)} color="info" size="sm" className="mt-2">Başvuru Dilekçesini Görüntüle</Button>
                </div>
              )
                : 'Başvuru dilekçesi yüklenmedi'}
            </div>
            <hr />
            {appealDocumentAttachmentList.length > 0 && (
            <div className="mt-4">
              <h5>Ek Belgeler</h5>
              <Row className="mt-3">
                {appealDocumentAttachmentList.map((item, index) => (
                  <Col xs={12} lg={4} className="mb-3 pb-2">
                    <h6>{item.fileName}</h6>
                    <div>
                      <Button onClick={() => downloadAttachmentDocument(initialValues.id, item.id)} className="mx-2" color="outline-success" size="sm">Belgeyi İndir</Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            )}
            { (initialValues.status === 'NEW' || initialValues.status === 'SENT') && (
            <>
              <div className="mb-3">
                
                Tüm cevapların doğru olduğuna eminseniz başvuruyu onaylayın.
                {initialValues.status !== 'SENT' && (
                <>Düzenleme istediğiniz sorular var ise hastanenin tekrar düzeltebilmesi için `&apos;`Revizyon Gönder`&apos;` butonuna tıklayın.</>
                )}
                Başvuruyu tamamen reddetmek için `&apos;`Başvuruyu Reddet`&apos;` butonuna tıklayın.
              </div>
              <NewMeetingDateandTeam
                onSubmit={handleTeamSelect}
                startDate={initialValues.startDate}
                endDate={initialValues.endDate}
                initialValues={initialValues}
                availableDates={initialValues.availableVisitDateDtoList}
              />
              <Button
                color="primary"
                size="sm"
                className="me-2"
                onClick={() => confirmAppeal(initialValues.id)}
                disabled={!selectedTeam.visitDate || selectedTeam.visitMemberDtoList.length === 0}
              > 
                Onay Ver
              </Button>
              <Button
                className="btn-custom-warning me-2"
                size="sm"
                onClick={() => publishReview(initialValues.id)}
              > Revizyon Gönder
              </Button>
              <hr />
            </>
            )}
            { initialValues.status === 'APPEAL_REVISION' && (
            <>
              Hastaneye İletilen Cevaplarınızın Revizyonu Bekleniyor. <br />
              <hr />
            </>
            )}
            { ((initialValues.status === 'WAITING_VISIT_PLANNING') || (initialValues.status === 'REVISIT_WAITING_DATE_SELECTION')) && (
            <>
              {initialValues.revisitDate ? (
                <>
                  Tekrar yerinde görüşme tarihi: {initialValues.revisitDate}
                </>
              )
                : (
                  <>
                    Hastanenin müsait olduğu tarihler aşağıda listelenmektedir.
                    Hangi Tarihlerde Yerinde Görüşme Yapacak {initialValues.status === 'REVISIT_WAITING_DATE_SELECTION' ? '' : 'Ekibi Ve' } Görüşme Tarihini Seçiniz. <br />
                    <OnSiteMeetingDateAndTeamSelectForm
                      onSubmit={handleTeamSelect}
                      startDate={initialValues.startDate}
                      endDate={initialValues.endDate}
                      initialValues={initialValues}
                    />
                  </>
                ) }

              <hr />
            </>
            )}
            {(initialValues.status === 'READY_TO_VISIT' || initialValues.status === 'REVISIT_REQUESTED') && (
            <>
              Hastanenin müsait tarihleri seçmesi bekleniyor.<br />
            </>
            )}
            {(initialValues.status === 'SENT' || initialValues.status === 'APPEAL_REVISION') && (
              <>
                <GenericModal
                  isModalOpen={props.isOpen}
                  button={<Button color="danger" size="sm" className=""> Başvuruyu Reddet</Button>}
                  toggleModal={props.toggle}
                  content={(
                    <div>
                      <RejectAppealForm onSubmit={rejectAppeal} />
                    </div>
              )}
                />
                <hr />
              </>
            )}
            {initialValues.status === 'MEMBER_CONFIRMATION' && (
            <>
              <div>Kurul üyelerinin incelemeleri doğrultusunda akreditasyon almaya uygun olduğu belirlenmiştir.</div>
              {userRolesControl('ADMIN')
                  && (
                  <>
                    <div>
                      <Button className="btn-custom-success" size="sm" onClick={() => toggleAccForm()}>Akreditasyonu Onayla</Button>
                    </div>
                    <div className="mt-2">
                      {showAccForm
                    && <AccreditationConfirmForm onSubmit={confirmAccredidation} />}
                    </div>
                  </>
                  )}
              <hr />
            </>
            )}
            {initialValues.status === 'WAITING_DATE_SELECTION' && (
            <>
              <div>Hastanenin uygun tarihleri seçmesi bekleniyor.</div>
              <hr />
            </>
            )}
            {initialValues.status === 'VISIT_REVIEW' && (
            <>
              <div>Kurul üyelerinin yerinde değerlendirmeleri tamamlamaları bekleniyor. Yerinde değerlendirmeler sekmesinden ilgili başvurunun detaylarına erişebilirsiniz.</div>
              <hr />
            </>
            )}
            {((initialValues.status === 'NEW')
            || (initialValues.status === 'SENT')
            || (initialValues.status === 'READY_TO_VISIT')
            || (initialValues.status === 'VISIT_REVIEW')
            || (initialValues.status === 'WAITING_DATE_SELECTION')
            || (initialValues.status === 'WAITING_VISIT_PLANNING')
            || (initialValues.status === 'REVISION_REPORT_REQUESTED')
            || (initialValues.status === 'REVISIT_REQUESTED')
            || (initialValues.status === 'REVISION_REPORT_SENT')
            || (initialValues.status === 'MEMBER_CONFIRMATION')) && (
            <>
              <GenericModal
                isModalOpen={isExtendDateModalOpen}
                button={<Button className="btn-custom-success mt-2" size="sm"> Ek Süre Tanımla</Button>}
                toggleModal={toggleExtendDate}
                size="md"
                content={(
                  <div>
                    <ExtendDateForm onSubmit={extendDateSubmit} cancelButtonOnclick={toggleExtendDate} />
                  </div>
              )}
              />
            </>
            )}

          </CardBody>
        </Card>
      </TabPanel>
      <LocationModal isOpen={isLocationModalOpen} toggle={toggleLocationModal} status={selectedStatus}  step={2}/>
    </Box>
  );
}
