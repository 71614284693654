import {
  ADD_NOTIFICATION,
} from '../actionTypes/actionTypes';

const initialState = {
  message: null,
  level: 'error',
  position: 'br',
  autoDismiss: 5,
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        message: action.message,
        level: action.level,
        position: action.position,
        autoDismiss: action.autoDismiss,
      };
    default:
      return state;
  }
}
