/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, ButtonGroup, Row,
} from 'reactstrap';
import { Field, reduxForm, initialize, FormSection, FieldArray, reset, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import Alert from '../../../../shared/components/Alert';
// import { info, viewInfo } from '../../../../redux/actions/form/infoAction';
import { Button } from '@material-ui/core';
import { postById } from '../../redux/actions/byIdAction';
import { renderLoginFieldInput } from '../../shared/components/generic-form/fieldRenders';
import FormWithLoading from '../../shared/components/form/FormWithLoading';
import ChangePasswordForm from './ChangePasswordForm';
import { putAction } from '../../redux/actions/putAction';
// import { rules } from '../../../../shared/components/generic-form/validate';
// import PageTitleFilter from '../../../../shared/components/PageTitleFilter';
// import ReactTooltip from '../../../../../node_modules/react-tooltip';
const selector = formValueSelector('Request_Filter_Form');

class RequestFilterForm extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.userPasswordChangeSuccess !== prevProps.userPasswordChangeSuccess && this.props.userPasswordChangeSuccess) {
      this.props.history.push('/');
    }
  }
    onSubmit = (values) => {
      if (values.password === values.rePassword) {
        this.props.changePassword({password: values.password});
      }
    }
    render() {
      return (
        <Card>
          <CardBody className="p-0 p-md-2">
            <div className="text-center" style={{ paddingTop: '60px' }}>
              <div className="mx-auto p-4" style={{ maxWidth: '425px' }}>
                <div>
                  <h6 className="active">Şifre Değiştir</h6>
                  <hr />
                  <br />
                  <br />
                  <div className="fadeIn first mb-3">
                    <ChangePasswordForm onSubmit={this.onSubmit} />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword(values) {
      dispatch(putAction('user/pass/change', values));
    },
  };
};
const mapStateToProps = (state, ownProps) => ({
  userPasswordChangeSuccess: state.userPasswordChange.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestFilterForm);
