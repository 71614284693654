/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import ReactSelect from 'react-select';
import { Chip } from '@material-ui/core';
import Table from '../../../shared/components/generic-table/Table';
import { getById } from '../../../redux/actions/byIdAction';
import AnswerApplicationForm from './AnswerApplicationForm';
import WaitingApplicationTabsContainer from '../Containers/WaitingApplicationTabsContainer';
import GenericModal from '../../../shared/components/GenericModal';
import OnSiteAssesmentsTabsContainer from '../Containers/OnSiteAssesmentsTabsContainer';
import { getVisit } from '../../../redux/actions/visitAction';
import CreateVisitReview from '../../UsersDashboard/CreateVisitReview';
import {
  appealStatus, appealStatusOptionList, tableFilterStyles, visitStatus,
} from '../../App/helper/statusTableLookupValues';

class WaitingApplicationsTable extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchTableData: PropTypes.func,
    setSelectedRows: PropTypes.func,
    setTablePage: PropTypes.func,
    setTablePageSize: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      selectedRowData: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      accConfirmAppealSuccess, fetchTableData, uploadvisitReviewSuccess, reviewPublishVisitSuccess,
    } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if ((prevProps.accConfirmAppealSuccess !== accConfirmAppealSuccess && accConfirmAppealSuccess)
     || (prevProps.uploadvisitReviewSuccess !== uploadvisitReviewSuccess && uploadvisitReviewSuccess)
     || (prevProps.reviewPublishVisitSuccess !== reviewPublishVisitSuccess && reviewPublishVisitSuccess)) {
      this.toggle();
      fetchTableData();
    }
  }

  changePage = (page) => {
    const { setTablePage, fetchTableData } = this.props;
    setTablePage(page);
    fetchTableData();
  }

  changeRowsPerPage = (itemsPerPagina) => {
    const { setTablePageSize, fetchTableData } = this.props;
    setTablePageSize(itemsPerPagina);
    fetchTableData();
  }

  setSelectedRow = (rowData) => {
    const { history, getVisitData } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        selectedRowData: rowData,
        prevSelectedRowId: rowData.id,
      });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  render() {
    const {
      listVisit, setSelectedRow, tabs, changeTab, currentTabIndex, filterValues, fetchTableData, history,
    } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId, selectedRowData,
    } = this.state;
    console.log('listVisit', listVisit);
    const confirmedHospitals = { 
      ...listVisit, 
      tableData: listVisit.data?.filter(el => el.appealDto.status !== 'CONFIRMED') || [],
    };
    return (
      <div>
        
        <Table
          data={confirmedHospitals}
          newButtonBool={false}
          refreshButtonBool
          refreshButtonAction={() => fetchTableData()}
          columns={[
            { title: 'Başvuru No', field: 'appealDto.code' },
            { title: 'Görüşme No', field: 'code' },
            { title: 'Başvuru tarihi', field: 'appealDto.appealTime' },
            { title: 'Hastane Adı', field: 'appealDto.hospitalDto.title' },
            { title: 'Hastane İli', field: 'appealDto.hospitalDto.countyDto.cityDto.name' },
            { title: 'Hastane İlçesi', field: 'appealDto.hospitalDto.countyDto.name' },
            { title: 'Yerinde Görüşme Tarihi', field: 'visitDate' },
            {
              title: 'Başvuru Durumu',
              field: 'appealDto.status',
              lookup: appealStatus,
              filterComponent: ({ columnDef, onFilterChanged }) => (
                <ReactSelect
                  options={appealStatusOptionList}
                  placeholder="Durum"
                  styles={tableFilterStyles}
                  onChange={(e) => {
                    // Calling the onFilterChanged with the current tableId and the new value
                    onFilterChanged(columnDef.tableData.id, e.value);
                  }}
                />
              ),
            },
            {
              title: 'Yerinde Değerlendirme Durumu',
              field: 'status',
              lookup: visitStatus,
              filterComponent: ({ columnDef, onFilterChanged }) => (
                <ReactSelect
                  options={Object.entries({ '': <Chip className="tableChip bg-primary" size="small" label="Tümü" />, ...visitStatus }).map((el) => ({ value: el[0], label: el[1] }))}
                  placeholder="Durum"
                  styles={tableFilterStyles}
                  onChange={(e) => {
                  // Calling the onFilterChanged with the current tableId and the new value
                    onFilterChanged(columnDef.tableData.id, e.value);
                  }}
                />
              ),
            },
          ]}
          setSelectedRow={this.setSelectedRow}
          selectedRowId={selectedRowId}
          prevSelectedRowId={prevSelectedRowId}
          changeRowsPerPage={this.changeRowsPerPage}
          changePage={this.changePage}
          maxBodyHeight={false}
        />
        <GenericModal
          isModalOpen={isModalOpen}
          button={<Button className="d-none my-2" style={{ width: 0 }} />}
          toggleModal={this.toggle}
          onClosed={() => this.setSelectedRow(null)}
          content={(
            <div>
              <CreateVisitReview mode="view" id={selectedRowId} />
            </div>
              )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(getVisit('list'));
  },
});
const mapStateToProps = (state) => ({
  listVisit: state.listVisit,
  accConfirmAppealSuccess: state.accConfirmAppeal.success,
  uploadvisitReviewSuccess: state.uploadvisitReview.success,
  reviewPublishVisitSuccess: state.reviewPublishVisit.success,

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaitingApplicationsTable));
