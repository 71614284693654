/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { renderFieldInput, renderLoginFieldInput } from '../../../shared/components/generic-form/fieldRenders';
import LoginInputSection from '../../../shared/components/generic-form/FormSections/SingleSections/LoginInputSection';

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      handleSubmit, submitting, pristine, t, loginType,
    } = this.props;
    const { showPassword } = this.state;
    return (
      <>
        <form className="form mt-1" onSubmit={handleSubmit}>
          <LoginInputSection
            name="username"
            component={renderLoginFieldInput}
            placeholder="E-posta"
            type="text"
            customClassName="fadeIn second loginput form-control"
          />
          <div className="d-flex">
            <LoginInputSection
              name="password"
              component={renderLoginFieldInput}
              type={showPassword ? 'text' : 'password'}
              customClassName="fadeIn second loginput form-control"
              placeholder="Şifre"

            />
            <i className={`far fa-eye${showPassword ? '-slash' : ''}`} style={{ cursor: 'pointer', marginRight: '-30px', paddingLeft: '8px', marginBottom: '1rem', alignSelf: 'center' }} onClick={(e) => this.showPassword(e)} />
          </div>

          <Button className="fadeIn fourth btn-login underlineHover w-100" type="submit">GİRİŞ YAP</Button>
        </form>

      </>
    );
  }
}

export default reduxForm({
  form: 'Login_Form',
})(withRouter(LoginForm));
