/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable object-shorthand */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable object-curly-newline */
/* eslint-disable no-prototype-builtins */

import axios from 'axios';
import { addNotification } from './notificationActions';
import { authHeader } from '../../containers/App/helper/auth-header';
// import { userRolesControl, getUserId } from '../../containers/App/helper/auth-user-info';

function request(namespace) {
  return {
    type: `${namespace}/CONFIRM_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/CONFIRM_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/CONFIRM_FAILURE`,
    error,
  };
}


export function confirm(namespace, id) {
  return dispatch => {
    dispatch(request(namespace));
    axios.put(`${process.env.REACT_APP_API_URL}/${namespace}/confirm/${id}`, {}, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(addNotification('save_success', 'success'));
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}
export function postByIdWithId(namespace, id, secondId, values) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/${id}/${secondId}`, values, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(addNotification('save_success', 'success'));
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}

