/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
import FileUploadFormSection from '../../../shared/components/generic-form/FormSections/FileUploadFormSection';

class AddExtraDocument extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }
    onChange = (e) => {
      const { uploadImage, initialData } = this.props;
      this.setState(() => ({ file: e.target.files[0] }));
    }
    render() {
      const {
        pristine, submitting, toggle, reset, handleSubmit, change, // redux-form props
        initialValues, formLoading, title, data, setCitizenModalMode, selectCitizen, toggleModal,
      } = this.props;
      return (
        <Col md={12} lg={12}>
          <h5>{title}</h5>
          <FormWithLoading loading={formLoading} onSubmit={handleSubmit} className="form form--horizontal px-2">

            <InputSection
              label="Belge Adı"
              name="title"
              validate={['required']}
            />

            <FileUploadFormSection
              name="file"
              label="Belge Seç"
              onChange={this.onChange}
              initialValues={initialValues && initialValues}
            />

            <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
              <>
                <Row className="w-100 mt-2">
                  <Col xs={12} sm={6} className="mb-2 text-center">
                    <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2 text-center">
                    <Button style={{ width: '180px' }} onClick={() => toggle()} color="secondary" size="sm">İptal</Button>
                  </Col>
                </Row>
              </>
            </ButtonToolbar>
          </FormWithLoading>
        </Col>
      );
    }
}

AddExtraDocument = reduxForm({
  form: 'AddExtraDocument_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmit: (values, dispatch, props, state) => {
    props.upload(values, this.state.file);
  },
})(AddExtraDocument);

export default connect()(AddExtraDocument);
