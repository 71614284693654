/* eslint-disable import/no-anonymous-default-export */

/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
  LOGOUT,
} from '../actions/authAction';

let user = localStorage.getItem('userInfo');
let token = localStorage.getItem('token');

const initialState = {
  user: user ? { loggedIn: true, user } : {},
  token: token ? token : '',
  error: '',
  pendingRefreshingToken: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        token: action.token,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        token: action.token,
      };
    }
    case LOGIN_FAILURE: {
      return { ...state, error: 'Girdiğiniz kullanıcı adı veya şifre yanlış.' };
    }
    case TOKEN_REFRESH_REQUEST: {
      return {
        ...state,
        // token: action.token,
        pendingRefreshingToken: true,
      };
    }
    case TOKEN_REFRESH_SUCCESS: {
      return {
        ...state,
        token: action.token,
        pendingRefreshingToken: null,
      };
    }
    case TOKEN_REFRESH_FAILURE: {
      return {
        ...state,
        error: 'Girdiğiniz kullanıcı adı veya şifre yanlış.',
        pendingRefreshingToken: null,
      };
    }
    case LOGOUT: {
      return { ...state };
    }
    default:
      return state;
  }
}
