/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  ButtonToolbar, Button,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import MaskedInputSection from '../../../shared/components/generic-form/FormSections/SingleSections/MaskedInputSection';

class NewTeamForm extends PureComponent {
  componentDidUpdate(prevProps) {
    const { initialValues, initialize, personnelstatusList, form, change } = this.props;
  }

  render() {
    const {
      initialValues, formLoading, getPersonnelTitleList, departmentListTable, mode,
      pristine, submitting, reset, handleSubmit, personnelstatusList, // redux-form props
    } = this.props;
    return (
      <FormWithLoading loading={formLoading} className="form form--horizontal" onSubmit={handleSubmit}>
        <InputSection
          label="Ekip Adı Giriniz"
          name="teamName"
          validate={['required']}
          initialValues={initialValues}
          style={{ marginBottom: '2rem' }}
        />
        <SelectSection
          label="Ekip Üyesi Seçiniz"
          name="3"
          // validate={['required']}
          mode={mode}
          initialValues={initialValues}
          options={[{ value: '1', label: 'Hüseyin Temuçin' }, { value: '2', label: 'Furkan Pala' }, { value: '3', label: 'Elif Çınar' }]}
        />
        <SelectSection
          label="Ekip Üyesi Seçiniz"
          name="2"
          // validate={['required']}
          mode={mode}
          initialValues={initialValues}
          options={[{ value: '1', label: 'Hüseyin Temuçin' }, { value: '2', label: 'Furkan Pala' }, { value: '3', label: 'Elif Çınar' }]}
        />
        <SelectSection
          label="Ekip Üyesi Seçiniz"
          name="1"
          // validate={['required']}
          mode={mode}
          initialValues={initialValues}
          options={[{ value: '1', label: 'Hüseyin Temuçin' }, { value: '2', label: 'Furkan Pala' }, { value: '3', label: 'Elif Çınar' }]}
        />
        <SelectSection
          label="Ekip Üyesi Seçiniz"
          name="4"
          // validate={['required']}
          mode={mode}
          initialValues={initialValues}
          options={[{ value: '1', label: 'Hüseyin Temuçin' }, { value: '2', label: 'Furkan Pala' }, { value: '3', label: 'Elif Çınar' }]}
        />
        <SelectSection
          label="Ekip Üyesi Seçiniz"
          name="5"
          // validate={['required']}
          mode={mode}
          initialValues={initialValues}
          options={[{ value: '1', label: 'Hüseyin Temuçin' }, { value: '2', label: 'Furkan Pala' }, { value: '3', label: 'Elif Çınar' }]}
        />
        {mode !== 'view' &&
        <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '1rem' }}>
          <Button onClick={reset} disabled={pristine || submitting}>Sıfırla</Button>
          <Button type="submit" disabled={pristine || submitting} color="success">Kaydet</Button>
          {/* <Button variant="contained" color="error" disabled={(initialValues === null || initialValues === undefined || !pristine) && !submitting}>Sil</Button> */}
        </ButtonToolbar>}
      </FormWithLoading>
    );
  }
}

NewTeamForm = reduxForm({
  form: 'New_Team_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewTeamForm);

export default (NewTeamForm);
