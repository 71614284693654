/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Card, CardBody } from 'reactstrap';
import NewVisitsTableContainer from './NewVisitsTableContainer';

export default function UsersDashboard() {
  return (
    <Card>
      <CardBody className="p-0 p-md-2">
        <NewVisitsTableContainer />
      </CardBody>
    </Card>
  );
}
