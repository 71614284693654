/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable quotes */
import { ADD_NOTIFICATION } from '../actionTypes/actionTypes';
import { messages } from '../constants/messages';

export function addNotification(messageText, level, position = 'tr', autoDismiss = 7, extraMessage) {
  const message = messages[messageText] || messageText;

  return {
    type: ADD_NOTIFICATION,
    message,
    level,
    position,
    autoDismiss,
  };
}
