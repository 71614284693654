/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <div className="theme-light ltr-support">
        <div className="wrapper">
          {children}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  theme: state.theme,
}))(MainWrapper);
