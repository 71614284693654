/* eslint-disable react/no-did-update-set-state */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Row, Collapse, Button,
} from 'reactstrap';
import { getAppeal, postAppeal, putAppeal } from '../../redux/actions/appealAction';
import { downloadWithId } from '../../redux/actions/downloadAction';
import { list } from '../../redux/actions/listAction';
import { getVisit, postVisit, putVisit } from '../../redux/actions/visitAction';
import AnswerApplicationForm from '../Applications/compontents/AnswerApplicationForm';
import WaitingApplicationTabsContainer from '../Applications/Containers/WaitingApplicationTabsContainer';

class ApplicationDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showAccForm: false,
    };
  }

  componentDidMount() {
    const { getAppealData, id, listAttachmentDocuments } = this.props;
    if (id) {
      getAppealData(id);
      listAttachmentDocuments(id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      reviewAddAppealSuccess, id, getAppealData, reviewPublishAppealSuccess, history,
      confirmVisitAppealSuccess, createVisitSuccess, nutritionReviewAddAppealSuccess,
      rejectAppealSuccess, accConfirmAppealSuccess, extendAppealSuccess, visitSetDateSuccess,
    } = this.props;
    if ((prevProps.reviewAddAppealSuccess !== reviewAddAppealSuccess && reviewAddAppealSuccess)
    || (prevProps.reviewPublishAppealSuccess !== reviewPublishAppealSuccess && reviewPublishAppealSuccess)
    || (prevProps.confirmVisitAppealSuccess !== confirmVisitAppealSuccess && confirmVisitAppealSuccess)
    || (prevProps.createVisitSuccess !== createVisitSuccess && createVisitSuccess)
    || (prevProps.rejectAppealSuccess !== rejectAppealSuccess && rejectAppealSuccess)
    || (prevProps.nutritionReviewAddAppealSuccess !== nutritionReviewAddAppealSuccess && nutritionReviewAddAppealSuccess)
    || (prevProps.extendAppealSuccess !== extendAppealSuccess && extendAppealSuccess)
    || (prevProps.visitSetDateSuccess !== visitSetDateSuccess && visitSetDateSuccess)) {
      getAppealData(id);
    }

    if ((prevProps.createVisitSuccess !== createVisitSuccess && createVisitSuccess)
    || (prevProps.accConfirmAppealSuccess !== accConfirmAppealSuccess && accConfirmAppealSuccess)) {
      history.push('/applications');
    }
    if (prevProps.extendAppealSuccess !== extendAppealSuccess && extendAppealSuccess) {
      this.setState({ isExtendDateModalOpen: false });
    }
  }

  revisionAnswer = (values, id) => {
    const { review } = this.props;
    review(values, id);
  }

  publishReview = (id) => {
    const { publish } = this.props;
    publish(id);
  }

  confirmAppeal = (id) => {
    const { confirm } = this.props;
    confirm(id);
  }

  setCouncilAndDate = (values) => {
    const {
      createVisit, id, initialValues, setVisitDate,
    } = this.props;
    if (initialValues.status === 'REVISIT_WAITING_DATE_SELECTION') {
      setVisitDate(id, values.visitDate.split('/').reverse().join('-'));
    } else {
      const visitMemberDtoList = [];
      values.councilMembers.forEach((councilMember) => {
        if (councilMember.id === values.headOfCommitteeId) {
          visitMemberDtoList.push({ councilMemberDto: councilMember, headOfCommittee: true });
        } else {
          visitMemberDtoList.push({ councilMemberDto: councilMember });
        }
      });
      values.externalMembers.forEach((externalMember) => {
        if (externalMember.id === values.headOfCommitteeId) {
          visitMemberDtoList.push({ externalVisitorDto: externalMember, headOfCommittee: true });
        } else {
          visitMemberDtoList.push({ externalVisitorDto: externalMember });
        }
      });
      const sendData = { visitMemberDtoList, visitDate: values.visitDate };
      createVisit(sendData, id);
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  onSubmitRejectAppeal = (values, id) => {
    const { rejectAppeal } = this.props;
    rejectAppeal(values, id);
  }

  onSubmitRevisionNutrition = (values, id) => {
    const { revisionNutrition } = this.props;
    revisionNutrition(values, id);
  }

  confirmAccredidation = (values) => {
    const { accredidate, initialValues } = this.props;
    const id = initialValues && initialValues.id;
    accredidate(id, values);
  }

  toggleAccForm = () => {
    const { showAccForm } = this.state;
    this.setState((prevState) => ({ showAccForm: !prevState.showAccForm }));
  }

  toggleExtendDate = () => {
    this.setState((prevState) => ({ isExtendDateModalOpen: !prevState.isExtendDateModalOpen }));
  }

  onChangePdf = (e, id) => {
    const { uploadDocument } = this.props;
    if (e.target.files[0]) {
      uploadDocument(e.target.files[0], id);
    }
  }

  extendDateSubmit = (values) => {
    const { extendDate, id } = this.props;
    if (values.days) {
      extendDate(id, values.days);
    }
  }

  downloadDocument = (id) => {
    const { downloadDoc } = this.props;
    downloadDoc(id);
  }

  listAttachmentDocuments = (appealId) => {
    const { listAttachmentDocuments } = this.props;
    listAttachmentDocuments(appealId);
  }

  downloadAttachmentDocument = (appealId, docId) => {
    const { downloadAttachmentDoc } = this.props;
    downloadAttachmentDoc(appealId, docId);
  }

  render() {
    const {
      initialValues, id, reviewAddAppealSuccess, initialValuesSuccess, history, appealDocumentAttachmentList,
    } = this.props;
    const { isOpen, showAccForm, isExtendDateModalOpen } = this.state;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <Button className="mb-2 px-4" color="outline-info" size="sm" onClick={() => history.goBack()}> <i className="lnr lnr-arrow-left me-2" /> Geri</Button>
          <span style={{ fontSize: '1.25rem', marginLeft: '1rem' }}>{initialValuesSuccess && initialValues?.hospitalDto?.title}</span>
          <Row>
            <WaitingApplicationTabsContainer
              id={id}
              initialValues={initialValuesSuccess && initialValues}
              component={AnswerApplicationForm}
              revisionAnswer={this.revisionAnswer}
              publishReview={this.publishReview}
              confirmAppeal={this.confirmAppeal}
              setCouncilAndDate={this.setCouncilAndDate}
              reviewAddAppealSuccess={reviewAddAppealSuccess}
              toggle={this.toggle}
              isOpen={isOpen}
              onSubmitRejectAppeal={this.onSubmitRejectAppeal}
              confirmAccredidation={this.confirmAccredidation}
              toggleAccForm={this.toggleAccForm}
              showAccForm={showAccForm}
              toggleExtendDate={this.toggleExtendDate}
              isExtendDateModalOpen={isExtendDateModalOpen}
              extendDateSubmit={this.extendDateSubmit}
              onSubmitRevisionNutrition={this.onSubmitRevisionNutrition}
              onChangePdf={this.onChangePdf}
              downloadDocument={this.downloadDocument}
              appealDocumentAttachmentList={appealDocumentAttachmentList}
              downloadAttachmentDocument={this.downloadAttachmentDocument}
            />
          </Row>
        </CardBody>
      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAppealData(id) {
    dispatch(getAppeal('id', id));
  },
  review(values, id) {
    dispatch(postAppeal('review/add', values, id));
  },
  publish(id) {
    dispatch(putAppeal('review/publish', {}, id));
  },
  confirm(id) {
    dispatch(putAppeal('confirm/visit', {}, id));
  },
  createVisit(values, id) {
    dispatch(postVisit('create', values, id));
  },
  rejectAppeal(values, id) {
    dispatch(putAppeal('reject', values, id));
  },
  revisionNutrition(values, id) {
    dispatch(putAppeal('nutrition/review/add', values, id));
  },
  accredidate(id, values) {
    dispatch(putAppeal('confirm/acc', values, id));
  },
  extendDate(appealId, days) {
    dispatch(putAppeal('extend', {}, appealId, days));
  },
  downloadDoc(id) {
    dispatch(downloadWithId('appeal/document/appointment', id));
  },
  listAttachmentDocuments(appealId) {
    dispatch(list('appeal/document/attachment', false, appealId));
  },
  downloadAttachmentDoc(appealId, docId) {
    dispatch(downloadWithId('appeal/document/attachment', appealId, docId));
  },
  setVisitDate(id, dateString) {
    dispatch(putVisit('set/date', {}, id, dateString));
  },
});
const mapStateToProps = (state, ownProps) => ({
  initialValues: state.idAppeal.data,
  initialValuesSuccess: state.idAppeal.success,
  reviewAddAppealSuccess: state.reviewAddAppeal.success,
  reviewPublishAppealSuccess: state.reviewPublishAppeal.success,
  confirmVisitAppealSuccess: state.confirmVisitAppeal.success,
  createVisitSuccess: state.createVisit.success,
  rejectAppealSuccess: state.rejectAppeal.success,
  accConfirmAppealSuccess: state.accConfirmAppeal.success,
  nutritionReviewAddAppealSuccess: state.nutritionReviewAddAppeal.success,
  extendAppealSuccess: state.extendAppeal.success,

  appealDocumentAttachmentList: state.appealDocumentAttachment.data,

  visitSetDateSuccess: state.visitSetDate.success,

});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
