/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { React, PureComponent } from 'react';
// import { Button } from 'reactstrap';
import { Field } from 'redux-form';
import { RenderFieldInput } from './SingleSections/InputSection';
import { rules } from './validate';

class PasswordFormSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showRePassword: false,
    };
  }

  showPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  showRePassword = () => {
    this.setState((prevState) => ({ showRePassword: !prevState.showRePassword }));
  };

  render() {
    const { showPassword, showRePassword } = this.state;
    return (
      <>
        <div className="form__form-group">
          <span className="form__form-group-label">Şifre
          </span>
          <div className="form__form-group-field" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
              <Field
                name="password"
                component={RenderFieldInput}
                type={showPassword ? 'text' : 'password'}
                validate={rules.required}
              // style={{ width: '100%' }}
                lowerCaseBool
              />
            </div>
            <div>
              <i style={{ flexGrow: 0, cursor: 'pointer', marginLeft: '8px' }} onClick={this.showPassword} className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
            </div>
          </div>
        </div>
        <div className="form__form-group mt-3">
          <span className="form__form-group-label">Şifre(Tekrar)
          </span>
          <div className="form__form-group-field" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
              <Field
                name="rePassword"
                component={RenderFieldInput}
                type={showRePassword ? 'text' : 'password'}
                validate={[rules.required, rules.equal_password]}
              // style={{ width: '100%' }}
                lowerCaseBool
              />
            </div>
            <div>
              <i style={{ flexGrow: 0, cursor: 'pointer', marginLeft: '8px' }} onClick={this.showRePassword} className={`fas fa-eye${showRePassword ? '-slash' : ''}`} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PasswordFormSection;
