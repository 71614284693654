/* eslint-disable react/static-property-placement */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */

import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { Checkbox } from '@material-ui/core';

const renderCheckBoxField = (props) => {
  const {
    input, label, defaultChecked, disabled, className, color,
  } = props;
  useEffect(() => {
    if (input.value === '') {
      if (defaultChecked) {
        input.onChange(true);
      } else {
        input.onChange(false);
      }
    }
  }, []);
  return (
    <Checkbox
      {...input}
      checked={input.value}
      label={label}
      defaultChecked={defaultChecked}
      disabled={disabled}
      className={className}
      color={color}
    />
  );
};

renderCheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

renderCheckBoxField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
};

class CheckboxFormSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string,
    submessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    noLabelbool: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    label: undefined,
    submessage: undefined,
    noLabelbool: false,
    onChange: undefined,
  };

  render() {
    const {
      t, label, submessage, name, noLabelbool, onChange, disabled, disabledTooltipText,
      color, defaultChecked, initialValues, mode,
    } = this.props;

    return (
      <div className="form__form-group" style={{ display: 'inline-flex' }}>
        {label &&
          <div className="form__form-group-label">
            {label}
          </div>}
        <div className="form__form-group-field" style={noLabelbool ? { marginLeft: '0px' } : {}}>
          {mode !== 'view' ?
            <Field
              name={name}
              component={renderCheckBoxField}
              onChange={onChange}
              disabled={disabled}
              label={label}
              color={color || 'primary'}
              defaultChecked={defaultChecked}
            />
            :
            initialValues && (initialValues[name] !== undefined && initialValues[name] !== null)
              ? <i style={{ marginLeft: '3px', marginTop: '8px' }} className={`far fa-${initialValues[name] ? 'check' : 'times'}-circle ${initialValues[name] ? 'text-success' : 'text-danger'}`} />
              : <p style={{ marginLeft: '3px', marginTop: '8px' }}>-</p>}
          <span id={`checkbox_${name}`} className="form__form-group-description" style={{ marginLeft: '0px', color: disabled ? '#707070' : '#4D4F5C' }}>
            {submessage}
          </span>
          {disabled && <UncontrolledTooltip fade delay={{ show: 100, hide: 100 }} placement="bottom" target={`checkbox_${name}`}>{disabledTooltipText}</UncontrolledTooltip>}
        </div>
      </div>
    );
  }
}

export default CheckboxFormSection;
