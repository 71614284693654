/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import {
  Button, ButtonToolbar, CardBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';

class ExecutiveInfoForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const {
      handleSubmit, reset, pristine, t, submitting, initialValues,
    } = this.props;
    const { showPassword } = this.state;
    return (
      <>
        <FormWithLoading className="form form--horizontal" onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={6}>
              <InputSection
                label="Adı"
                name="name"
                type="text"
                validate={['required']}
                initialValues={initialValues}
              />
            </Col>
            <Col xs={12} lg={6}>
              <InputSection
                label="Soyadı"
                name="surname"
                type="text"
                validate={['required']}
                initialValues={initialValues}

              />
            </Col>
            <Col xs={12} lg={6}>
              <InputSection
                label="Görevi"
                name="duty"
                validate={['required']}
                type="text"
                initialValues={initialValues}
              />
            </Col>
            <Col xs={12} lg={6}>
              <InputSection
                label="Eposta"
                name="email"
                type="email"
                validate={['required']}
                isDisabled={!!initialValues.email}
                initialValues={initialValues}
              />
            </Col>
          </Row>
          <ButtonToolbar className="mt-5" style={{ justifyContent: 'space-evenly' }}>
            <Button type="submit" disabled={pristine || submitting} color="success">Kaydet</Button>
          </ButtonToolbar>
        </FormWithLoading>
      </>
    );
  }
}

export default reduxForm({
  form: 'Executive_Info_Form',
  enableReinitialize: true,
})(withRouter(ExecutiveInfoForm));
