/* eslint-disable import/prefer-default-export */
export const messages = {
  // info/success messages
  save_success: 'Başarıyla Kaydedildi',
  delete_success: 'Başarıyla Silindi',
  route_success: 'Başarıyla Yönlendirildi',
  cancel_success: 'Başarıyla İade Edildi',
  // error messages
  user_incorrect: 'Kullanıcı adı veya şifre yanlış',
  unknown_error: 'Bilinmeyen bir hata oluştu',
  500: 'Sunucu hatası',

};
