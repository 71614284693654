/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

class RadioButtonField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    defaultChecked: PropTypes.bool,
    radioValue: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    customLabelClass: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    defaultChecked: false,
    radioValue: false,
    disabled: false,
    className: '',
    customLabelClass: '',
  };

  componentDidMount() {
    const {
      defaultChecked, onChange, radioValue, value,
    } = this.props;
    if (defaultChecked && (value === '' || value === undefined)) {
      onChange(radioValue);
    }
  }

  componentDidUpdate() {
    const {
      defaultChecked, onChange, radioValue, value,
    } = this.props;
    if (defaultChecked && (value === '' || value === undefined)) {
      onChange(radioValue);
    }
  }

  handleClick = () => {
    const { onChange, radioValue } = this.props;
    onChange(radioValue);
  };

  render() {
    const {
      disabled, className, name, label, radioValue, value, customLabelClass,
    } = this.props;
    const RadioButtonClass = classNames({
      'radio-btn': true,
      disabled,
    });

    return (
      <label
        className={`${RadioButtonClass} ${customLabelClass} ${className ? ` radio-btn--${className}` : ''}`}
      >
        <input
          className="radio-btn__radio"
          name={name}
          type="radio"
          onClick={this.handleClick}
          checked={value === radioValue}
          disabled={disabled}
        />
        <span className="radio-btn__radio-custom" />
        {className === 'button'
          ? (
            <span className="radio-btn__label-svg">
              <i className="lnr lnr-check" />
              <i className="lnr lnr-cross" />
            </span>
          ) : ''}
        <span className="radio-btn__label">{label}</span>
      </label>
    );
  }
}

const renderRadioButtonField = (props) => {
  const {
    input, label, defaultChecked, disabled, className, radioValue, id, style, customLabelClass,
  } = props;
  return (
    <div className={className} style={style} id={id}>
      <RadioButtonField
        {...input}
        label={label}
        defaultChecked={defaultChecked}
        disabled={disabled}
        radioValue={radioValue}
        className={className}
        customLabelClass={customLabelClass}
      />
    </div>
  );
};

renderRadioButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,

};

renderRadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  radioValue: false,
  className: '',
  id: '',

};

export default renderRadioButtonField;
