/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

export default class FieldFileInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      name, label, onChange,
    } = this.props;
    // const { name, label } = this.props;
    return (
      <div>{label}
        <div>
          <input
            name={name}
            type="file"
            multiple
            accept=".pdf,"
            onChange={onChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}
