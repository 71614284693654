/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { CircularProgress } from '@material-ui/core';

export default (props) => {
  const { noLogo, color, style } = props;
  return (
    <div className="loading_wrapper">
      {!noLogo &&
      <div className="loading_logo_wrapper">
        <div className="loading_logo" style={{ backgroundImage: `url(${null})`, backgroundPosition: 'center' }} />
      </div>}
      <div className="loading_animation_wrapper">
        <CircularProgress thickness={1.5} disableShrink className="loading_spinner" color={color || 'primary'} style={style} size="5%" />
      </div>
    </div>
  );
};
