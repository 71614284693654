/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-lonely-if */
import axios from 'axios';
import store from '../../containers/App/store';
import { addNotification } from './notificationActions';
import { authHeader } from '../../containers/App/helper/auth-header';

function request(namespace) {
  return {
    type: `${namespace}/LIST_TABLE_DATA_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/LIST_TABLE_DATA_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/LIST_TABLE_DATA_FAILURE`,
    error,
  };
}

export function setTablePage(namespace, data) {
  return {
    type: `${namespace}/LIST_TABLE_PAGE`,
    data,
  };
}

export function resetTablePage(namespace) {
  return {
    type: `${namespace}/RESET_LIST_TABLE_PAGE`,
  };
}

export function setTablePageSize(namespace, data) {
  return {
    type: `${namespace}/LIST_TABLE_PAGE_SIZE`,
    data,
  };
}

export function setSelectedRows(namespace, data, selectedRowsData) {
  return {
    type: `${namespace}/LIST_TABLE_SET_SELECTED_ROWS`,
    data,
    selectedRowsData,
  };
}

export function resetSelectedRows(namespace) {
  return {
    type: `${namespace}/LIST_TABLE_RESET_SELECTED_ROWS`,
  };
}

export function fetchTableData(namespace) {
  return dispatch => {
    dispatch(request(namespace));
    axios.get(`${process.env.REACT_APP_API_URL}/${namespace}/list`, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          dispatch(addNotification(error.response.status, 'error'));
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}

export function fetchTableDataPost(namespace) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/list`, {}, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          dispatch(addNotification(error.response.status, 'error'));
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}
