/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable arrow-parens */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/no-unused-state */
import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class MultiSelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })),
    ]).isRequired,
    maxMenuHeight: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
    maxMenuHeight: '300px',
  };

  constructor(props) {
    super(props);
    this.state = {
      flag: false,
    };
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    this.setState({ flag: true });
    onChange(value);
  };

  render() {
    const {
      value, name, placeholder, options, isDisabled, maxMenuHeight, defaultValue,
    } = this.props;
    const customStyles = {
      menu: styles => ({
        ...styles,
        zIndex: '1000000',
      }),
      control: styles => ({
        ...styles,
        border: '1px solid #E9E9F0',
        height: '24px',
        minHeight: '24px',
        padding: '0',
        fontSize: '13px',
        alignItems: 'flex-start',
      }),
      valueContainer: () => ({
        height: '100%',
        display: 'contents',
        width: '100%',
        padding: 0,
      }),
      indicatorsContainer: () => ({
        height: '100%',
        display: 'flex',
        padding: '2px',
        alignItems: 'center',

      }),
      menuList: () => ({
        fontSize: '13px',
        maxHeight: '200px',
        overflowY: 'auto',
      }),
      dropdownIndicator: styles => ({
        ...styles,
        padding: '0px',
        fontSize: '12px',
        color: '#A4AFB7',
        '&:hover': {
          color: '#A4AFB7',
        },

      }),
      placeholder: styles => ({
        ...styles,
        color: '#CECFD0',
      }),
      indicatorSeparator: styles => ({
        ...styles,
        marginBottom: '12px',
        marginTop: '3px',
        color: 'red',
      }),
      clearIndicator: styles => ({
        ...styles,
        padding: '0px 8px',
      }),
      input: styles => ({
        ...styles,
        height: '100%',
        display: 'flex',
        width: 'min-content',
      }),
      multiValueLabel: styles => ({
        ...styles,
        padding: '0 6px',
      }),
      option: (styles, data) => ({
        ...styles,
        minHeight: '32px',
      }),
    };
    return (
      <Select
        isMulti
        name={name}
        // value={defaultValue}
        styles={customStyles}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        options={options}
        maxMenuHeight={maxMenuHeight}
        className={name.split('.')[0]}
        placeholder={placeholder}
        classNamePrefix="react-select"
        menuShouldScrollIntoView={false}
        defaultValue={defaultValue}
        disabled={isDisabled}
      />
    );
  }
}

const renderMultiSelectField = (props) => {
  const {
    input, meta, options, placeholder, groupedOptionsBool, maxMenuHeight, defaultValue, isDisabled,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <MultiSelectField
        {...input}
        options={options}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        groupedOptionsBool={groupedOptionsBool}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderMultiSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  groupedOptionsBool: PropTypes.bool,
  maxMenuHeight: PropTypes.string,
};

renderMultiSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  groupedOptionsBool: false,
  maxMenuHeight: '300',

};

export default renderMultiSelectField;
