/* eslint-disable max-len */
function getUserProfileJSON() {
  const userProfile = localStorage.getItem('userInfo');
  return (userProfile !== undefined && userProfile !== 'undefined' && userProfile !== null) ? JSON.parse(userProfile) : undefined;
}

export function getUserName() {
  // return (getUserProfileJSON() !== undefined && getUserProfileJSON() !== 'undefined' && getUserProfileJSON() !== null) ? getUserProfileJSON().name : undefined;
  return (getUserProfileJSON() !== undefined && getUserProfileJSON() !== 'undefined' && getUserProfileJSON() !== null) ? `${getUserProfileJSON().name || getUserProfileJSON().email}` : undefined;
}

export function getUserImage() {
  return (getUserProfileJSON() !== undefined && getUserProfileJSON() !== 'undefined' && getUserProfileJSON() !== null) ? getUserProfileJSON().profileBase64 : undefined;
}
